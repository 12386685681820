import { Button, Flex, Modal, TextInput } from '@mantine/core'
import { IconDiscount } from '@tabler/icons'
import { useState } from 'react'

export const OrderCheckoutDiscountModal = ({
  onClose,
  applyDiscount,
  disabled,
}: {
  onClose: () => unknown
  applyDiscount: (code: string) => unknown
  disabled?: boolean
}) => {
  const [discountCode, setDiscountCode] = useState('')

  return (
    <Modal opened onClose={onClose} title='Apply discount code'>
      <Flex direction={'column'} gap={'sm'}>
        <TextInput
          icon={<IconDiscount />}
          placeholder='Type your code in here'
          radius='xl'
          size='md'
          value={discountCode}
          onChange={(event) => setDiscountCode(event.currentTarget.value)}
        />

        <Button disabled={disabled} w={'100%'} onClick={() => applyDiscount(discountCode)}>
          Apply
        </Button>
      </Flex>
    </Modal>
  )
}
