import { useAccount } from '@azure/msal-react'
import { Button, createStyles, Flex, Loader } from '@mantine/core'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { getOrder } from '../../helpers/auth-api.helper'

import {
  MISTERIO_PRODUCT_TITLE,
  MISTERIO_PRODUCT_ID,
} from '../../products/misterio-project-product'
import { setUserProductIds, useGlobalState } from '../../state/state'
import { capitalise } from '../../utils/string.helper'
import { useQuery } from '../../utils/url.hook'

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
  },

  detailsWrapper: {
    margin: theme.spacing.md,
    width: '50%',
    backgroundColor: theme.colors[theme.primaryColor][8],
    borderRadius: '8px',

    [theme.fn.smallerThan('lg')]: {
      width: '65%',

      [theme.fn.smallerThan('md')]: {
        width: '75%',

        [theme.fn.smallerThan('sm')]: {
          width: '100%',
        },
      },
    },
  },

  detailsTop: {
    padding: theme.spacing.lg,
  },

  detailsHeader: {},

  detailsItems: {
    h2: {
      margin: 0,

      fontSize: theme.fontSizes.md,

      [theme.fn.smallerThan('xl')]: {
        fontSize: theme.fontSizes.md,
      },

      [theme.fn.smallerThan('md')]: {
        fontSize: theme.fontSizes.sm,
      },
    },
  },

  itemRow: {
    borderRadius: '8px',
    backgroundColor: theme.colors[theme.primaryColor][6],
    padding: theme.spacing.md,
    width: '100%',
    display: 'Flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    gap: theme.spacing.lg,

    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
    },
  },

  itemAvatarWrapper: {
    width: '50px',
    height: '50px',
  },

  itemAvatarContent: {
    width: '50px',
    height: '50px',
    background: 'black',
    borderRadius: '30px',
    borderStyle: 'solid',
    borderWidth: '2px',
    overflow: 'overlay',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    h1: {
      fontSize: `${theme.fontSizes.md}px !important`,
      margin: '0',
    },
  },

  detailsFooter: {
    padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  subText: {
    fontSize: theme.fontSizes.xl,

    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.lg,
    },
  },

  orderId: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.dark[5],

    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.xs,
    },
  },

  successMsg: {
    margin: 0,
  },

  control: {
    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.sm,
    },
  },

  price: {
    fontSize: theme.fontSizes.xl,

    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.lg,
    },
  },

  savedAmount: {
    fontSize: theme.fontSizes.md,

    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.sm,
    },
  },
}))

export const OrderDetails = (): JSX.Element => {
  const [existingProductIds] = useGlobalState('userProductIds')

  const isMounted = useRef(false)
  const { classes } = useStyles()

  const orderId = useQuery().get('orderId') ?? ''
  const status = capitalise(useQuery().get('redirect_status') ?? '')

  const user = useAccount()

  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [order, setOrder] = useState<any>(null)

  useEffect(() => {
    if (!user) {
      console.log('user is not present')
      return
    }

    if (!orderId) {
      console.log('orderId is empty')
      return
    }

    if (isMounted.current) {
      console.log('component mounted already')
      return
    }
    isMounted.current = true

    setLoading(true)

    getOrder(orderId)
      .then((data) => {
        setUserProductIds([...new Set([...existingProductIds, MISTERIO_PRODUCT_ID])])
        setOrder(data.order)
        setLoading(false)
      })
      .catch((e) => {
        setError(e.error || e.message || e)
        setLoading(false)
      })
  }, [user])

  // TODO: put into floaty box
  {
    /* {status === 'Succeeded' && (
                <Flex direction={'column'} justify={'center'} align={'center'}>
                  <h2 className={classes.successMsg}>🎊 🎉 Payment {status} 🎉 🎊</h2>
                  <p className={classes.subText}>You now have access to the Misterio Programme</p>
                  <br />
                </Flex>
              )} */
  }

  return (
    <Flex className={classes.wrapper} align={'center'} justify={'center'}>
      {loading && <Loader />}

      {!loading && (
        <>
          {order && (
            <Flex direction={'column'} className={classes.detailsWrapper}>
              <div className={classes.detailsTop}>
                <div className={classes.detailsHeader}>
                  <p className={classes.subText}>
                    {status || order.status} on {order.createdOn}
                  </p>
                  <p className={classes.subText}>
                    Paid with
                    <span> </span>
                    {order?.chargeData?.walletType ||
                      order?.chargeData?.cardBrand ||
                      order?.chargeData?.type}
                    <span> </span>
                    ending in {order?.chargeData?.last4 || 'Unknown'}
                  </p>

                  <p className={classes.orderId}>Order ID: {order.id}</p>
                </div>

                <br />

                <div className={classes.detailsItems}>
                  <div className={classes.itemRow}>
                    <div className={classes.itemAvatarWrapper}>
                      <div className={classes.itemAvatarContent}>
                        <h1>{order?.products[0]?.title[0]}</h1>
                      </div>
                    </div>

                    <Flex justify={'center'} align={'center'} gap={'md'}>
                      <h2>{MISTERIO_PRODUCT_TITLE}</h2>
                      <p style={{ whiteSpace: 'nowrap' }}>x 1</p>
                    </Flex>

                    <Link to={'/misterio'}>
                      <Button variant='default' radius='xl' size='md' className={classes.control}>
                        Go to Misterio
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div className={classes.detailsFooter}>
                <p className={classes.price}>
                      <p>Total: £{order?.products[0]?.displayTotal || ' - '}{' '}</p>
                      {order?.products[0]?.discountDisplaySavedAmount && (
                        <p className={classes.savedAmount}>
                          {' '}
                          {`${order?.products[0]?.discountPercentage}% discount (-£${order?.products[0]?.discountDisplaySavedAmount})`}
                        </p>
                      )}
                    </p>
              </div>
            </Flex>
          )}
          {!order && (
            <div>
              <p>Failed to load order</p>
              <p>{error}</p>
            </div>
          )}
        </>
      )}
    </Flex>
  )
}
