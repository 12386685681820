import { DocumentButton } from '../../shared/document-button'
import workoutFileIcon from '../../assets/misterio/file_workout.png'
import manualFileIcon from '../../assets/misterio/file_manual.png'
import { getAuthTokenHeader } from '../../helpers/auth-api.helper'

const API_URL_HOST =
  process.env.NODE_ENV === 'development' ? 'http://localhost:7071' : window.location.origin

export const manualDocument = (): DocumentButton => {
  const name = 'Misterio Manual'
  const id = 'misterio-manual'

  const opts = {
    onDownload: async () => {
      const authTokenHeader = await getAuthTokenHeader()

      const res = await fetch(`${API_URL_HOST}/api/get-document?documentId=${id}`, {
        method: 'POST',
        body: JSON.stringify({ fnHeaders: authTokenHeader }),
      })

      if (!res.ok) {
        throw new Error(await res.json())
      }

      const blob = await res.blob()
      const fileUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.download = `${name}.pdf`
      link.href = fileUrl
      link.click()
    },
    onView: async () => {
      const authTokenHeader = await getAuthTokenHeader()

      const res = await fetch(`${API_URL_HOST}/api/get-document?documentId=${id}`, {
        method: 'POST',
        body: JSON.stringify({ fnHeaders: authTokenHeader }),
      })

      if (!res.ok) {
        throw new Error(await res.json())
      }

      const blob = await res.blob()
      const fileUrl = window.URL.createObjectURL(blob)
      window.open(fileUrl)
    },
  }

  return {
    name,
    src: manualFileIcon,
    opts,
  }
}

export const workoutDocument = (
  stage?: number,
  opts?: { type?: 'xlsx' | 'xls' },
): DocumentButton => {
  const id = stage ? `stage-${stage}-workout-log` : 'stage-1-workout-log'
  const name = stage ? `Stage ${stage} - Workout log` : 'Workout Log'

  const docOpts = {
    onDownload: async () => {
      const authTokenHeader = await getAuthTokenHeader()

      const res = await fetch(`${API_URL_HOST}/api/get-document?documentId=${id}`, {
        method: 'POST',
        body: JSON.stringify({ fnHeaders: authTokenHeader }),
      })

      if (!res.ok) {
        throw new Error(await res.json())
      }

      const blob = await res.blob()
      const fileUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.download = `${name}.${opts?.type ?? 'xls'}`
      link.href = fileUrl
      link.click()
    },
    disableView: false,
    onView: async () => {
      const url = getExcelViewUrlByStage(stage ?? 1)

      if (url) window.open(url)
    },
  }

  return {
    name,
    src: workoutFileIcon,
    opts: docOpts,
  }
}

function getExcelViewUrlByStage(stage: number): string | undefined {
  switch (stage) {
    case 1:
      return 'https://drive.google.com/file/d/1D9itS9lEttA-tGiZx4uTDBZHphrS2XBF/view'
    case 2:
      return 'https://drive.google.com/file/d/1Sd7VJGJqmVF0RoY6jYtSPddRyUTmoCGg/view'

    case 3:
      return 'https://drive.google.com/file/d/1vKKV5aMKXeLddGBaNJgC2jKYGzQm3BK1/view'

    case 4:
      return 'https://drive.google.com/file/d/1hsS_RXQiRIMdky0B0YggHpeLSb2i3qZL/view'

    case 5:
      break
  }
}
