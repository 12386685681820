import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe, StripeElementsOptions } from '@stripe/stripe-js'
import { v4 as uuidv4 } from 'uuid'

import { OrderCheckoutForm } from '../../components/order/order-checkout-form'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from '@azure/msal-react'
import { Button, createStyles, Flex } from '@mantine/core'
import { loginRequest } from '../../auth-config'
import { useQuery } from '../../utils/url.hook'
import { OrderCheckoutProduct } from '../../components/order/order-checkout-product-provider'
import { MISTERIO_PRODUCT_ID } from '../../products/misterio-project-product'
import { setError, useGlobalState } from '../../state/state'
import { getOrderCheckoutIntent, orderIntentUpdate } from '../../helpers/auth-api.helper'
import { hasUserProduct } from '../../helpers/user.helper'
import { OrderCheckoutDiscountModal } from './order-checkout-discount'
import { showNotification } from '@mantine/notifications'

const SUCCESS_MESSAGE = 'Purchase succeeded'

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: theme.spacing.xl,
    gap: theme.spacing.xl,
    margin: '0 20%',

    [theme.fn.smallerThan('lg')]: {
      padding: theme.spacing.lg,
      margin: '0 16%',

      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column-reverse',
        padding: theme.spacing.lg,

        [theme.fn.smallerThan('sm')]: {
          margin: '0',
        },
      },
    },
  },

  cardWrapper: {
    width: '100%',
  },

  productDetailsWrapper: {
    width: '100%',
    backgroundColor: theme.colors[theme.primaryColor][8],
    borderRadius: '8px',
  },

  loginWrapper: {
    height: '100%',
    margin: '0 20%',

    [theme.fn.smallerThan('sm')]: {
      margin: '2%',
      justifyContent: 'start',
    },
  },

  loginContent: {
    padding: theme.spacing.lg,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.md,
    backgroundColor: theme.colors[theme.primaryColor][9],
    borderRadius: '8px',
  },

  footer: {
    padding: `${theme.spacing.md}px 0`,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  discountButton: {
    alignSelf: 'start',
    margin: `0 ${theme.spacing.xs}px`,
  },

  price: {
    padding: `0 ${theme.spacing.xl}px`,
    fontSize: theme.fontSizes.xl,

    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.lg,
    },
  },

  savedAmount: {
    fontSize: theme.fontSizes.md,

    [theme.fn.smallerThan('md')]: {
      fontSize: theme.fontSizes.sm,
    },
  },
}))

export const OrderCheckout = (): JSX.Element => {
  const [config] = useGlobalState('config')
  const stripeApiKey = config?.stripeApiKey
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)

  const hasMisterio = hasUserProduct(MISTERIO_PRODUCT_ID)
  const navigate = useNavigate()

  const { classes } = useStyles()
  const { instance } = useMsal()
  const user = useAccount()

  const isMounted = useRef(false)

  const [pageError, setPageError] = useState<string | null>(null)
  const [loadingStripe, setLoadingStripe] = useState<boolean>(true)

  const [stripePaymentIntentId, setStripePaymentIntentId] = useState<string | undefined>()
  const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions | null>()
  const [displayTotal, setDisplayTotal] = useState(config?.misterioProductPrice)
  const [displayedSavedAmount, setDisplayedSavedAmount] = useState<string | null>(null)
  const [displayedDiscountPercentage, setDisplayedDiscountPercentage] = useState<string | null>(null)
  const [orderId] = useState<string>(uuidv4())

  const productId = useQuery().get('productId') ?? ''

  const [discountModalOpened, setDiscountModalOpened] = useState<boolean>(false)
  const [discountCode, setDiscountCode] = useState<string | undefined>(undefined)
  useEffect(() => {
    const paymentIntentId = stripePaymentIntentId

    if (paymentIntentId) {
      orderIntentUpdate({ paymentIntentId, productId, discountCode })
        .then((res) => res.json())
        .then((data) => {
          handleIntentResponse(data)
          setDiscountModalOpened(false)
        })
    }
  }, [discountCode])

  useEffect(() => {
    if (hasMisterio) {
      navigate('/misterio')
      return
    }

    if (!user) {
      console.log('user is not present')
      return
    }

    if (!config) return

    if (isMounted.current) return
    isMounted.current = true

    // better way of doing the initialisation of checkout
    initCheckout().then()

    if (!config.stripeApiKey) {
      setError('Unable to load stripe. Key has not been configured')
    } else {
      setStripePromise(loadStripe(config.stripeApiKey))
    }
  }, [user, hasMisterio, stripeApiKey])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleIntentResponse = (data: any) => {
    setDisplayTotal(data.displayTotal)

    if (data.discount) {
      if (data.discount.percentage) {
        setDisplayedSavedAmount(data.discount.displaySavedAmount)
        setDisplayedDiscountPercentage(data.discount.percentage)

        showNotification({
          title: 'Discount applied 🎉',
          message: `You saved you ${data.discount.percentage}% !!`,
          color: 'cyan',
          autoClose: 6000,
        })
      } else if (data.discount.error) {
        showNotification({
          title: 'Discount not applied',
          message: data.discount.error,
          color: 'red',
          autoClose: 6000,
        })
      }
    } else {
      setDisplayedSavedAmount(null)
    }
  }

  const initCheckout = async () => {
    setLoadingStripe(true)
    setPageError(null)

    if (!productId || productId !== MISTERIO_PRODUCT_ID) throw new Error('Product id must be set')

    console.log('starting purchase process...')
    if (!user) {
      throw new Error('User must be logged in')
    }

    try {
      const res = await getOrderCheckoutIntent({
        orderId,
        productId,
        discountCode,
      })

      if (!res.ok) {
        const error: { error: string; message: string } = await res.json()

        console.error(error)
        setPageError(error.message)
        setLoadingStripe(false)
        return
      }

      const data = await res.json()

      setStripePaymentIntentId(data.paymentIntentId)
      if (!stripeOptions) {
        setStripeOptions({
          clientSecret: data.clientSecret,
          appearance: {
            theme: 'night',
          },
        })
      }

      handleIntentResponse(data)

      setLoadingStripe(false)
    } catch (e) {
      setPageError('Failed to load checkout, please try again later.')
      setError(
        'Failed to load checkout, please try again later. If the issue continues to persist - please get in touch with us (via contact page) and we will try to help!',
      )
      setLoadingStripe(false)

      throw e
    }
  }

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error))
  }

  return (
    <>
      {discountModalOpened && (
        <OrderCheckoutDiscountModal
          onClose={() => setDiscountModalOpened(false)}
          applyDiscount={setDiscountCode}
          disabled={loadingStripe}
        />
      )}

      <p>{pageError}</p>

      <AuthenticatedTemplate>
        <div>
          <Flex justify={'center'} pt={'lg'}>
            <h1 style={{ margin: 0 }}>Checkout</h1>
          </Flex>

          <Flex className={classes.wrapper}>
            <Flex style={{ flex: 1 }} justify={'center'} align={'center'}>
              <div className={classes.cardWrapper}>
                {stripeOptions && stripePromise && (
                  <Elements options={stripeOptions} stripe={stripePromise}>
                    <OrderCheckoutForm
                      displayTotal={displayTotal}
                      returnUrl={`${window.location.origin}/order-details?orderId=${orderId}?message=${SUCCESS_MESSAGE}`}
                    />
                  </Elements>
                )}
              </div>
            </Flex>

            <Flex style={{ flex: 1 }} justify={'center'} align={'center'}>
              <div className={classes.productDetailsWrapper}>
                <Flex direction={'column'}>
                  <OrderCheckoutProduct productId={productId} />
                  <Flex className={classes.footer} direction={'column'}>
                    <p className={classes.price}>
                      <p>Total: £{displayTotal || ' - '}{' '}</p>
                      {displayedSavedAmount && (
                        <p className={classes.savedAmount}>
                          {' '}
                          {`${displayedDiscountPercentage}% discount (-£${displayedSavedAmount})`}
                        </p>
                      )}
                    </p>
                    <Button
                      className={classes.discountButton}
                      size='xs'
                      onClick={() => setDiscountModalOpened(true)}
                    >
                      Apply discount code
                    </Button>
                  </Flex>
                </Flex>
              </div>
            </Flex>
          </Flex>
        </div>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Flex
          className={classes.loginWrapper}
          justify={'center'}
          align={'center'}
          direction={'column'}
        >
          <div className={classes.loginContent}>
            To make a purchase, please sign up or login to an account.
            <Button onClick={handleLoginRedirect} w={'100%'}>
              Sign up / Login
            </Button>
          </div>
        </Flex>
      </UnauthenticatedTemplate>
    </>
  )
}
