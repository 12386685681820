import { Button, createStyles, Flex, Modal } from '@mantine/core'
import { useState } from 'react'

const useStyles = createStyles(() => ({
  fileIconWrapper: {
    height: 140,
  },
}))

export interface DocumentButton {
  name: string
  src: string
  opts?: {
    onDownload?: () => Promise<unknown>
    onView?: () => Promise<unknown>
    disableView?: boolean
    disableDownload?: boolean
  }
}

export const DocumentButton = ({
  name,
  src,
  opts,
}: DocumentButton): JSX.Element => {
  const disableView = opts?.disableView || false
  const disableDownload = opts?.disableDownload || false

  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState(<></>)
  const [modalOpened, setModalOpened] = useState(false)

  const { classes } = useStyles()

  const openDocFileModal = (fileName: string) => {
    setModalTitle('Document options')
    setModalContent(
      <>
        <h3 style={{ margin: 0 }}>{fileName}</h3>

        <Flex direction={'column'} gap={'sm'}>
          {!disableView && (
            <Button w={'100%'} onClick={opts?.onView}>
              View
            </Button>
          )}
          {!disableDownload && (
            <Button w={'100%'} onClick={opts?.onDownload}>
              Download
            </Button>
          )}
        </Flex>
      </>,
    )
    setModalOpened(true)
  }

  return (
    <>
      <Flex
        key={name}
        className='underline-animation-wrapper'
        direction={'column'}
        justify={'center'}
        align={'center'}
        onClick={() => openDocFileModal(name)}
      >
        <img className={classes.fileIconWrapper} src={src}></img>
        <p className='hover-underline-animation'>{name}</p>
      </Flex>

      <Modal opened={modalOpened} onClose={() => setModalOpened(false)} title={modalTitle}>
        {modalContent}
      </Modal>
    </>
  )
}
