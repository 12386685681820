/* eslint-disable quotes */
import { CarouselCardProps } from '../../components/carousel'

import toolbarImg from '../../assets/photos/smoke_1.jpg'
import homeImg1 from '../../assets/photos/home_carousel/img_1.jpg'
import homeImg2 from '../../assets/photos/home_carousel/img_2.jpg'
import homeImg3 from '../../assets/photos/home_carousel/img_3.jpg'
import homeImg4 from '../../assets/photos/home_carousel/img_4.jpg'
import homeImg5 from '../../assets/photos/home_carousel/img_5.jpg'
import { Tile, TileOpts } from '../../components/tile'
import avatarImg from '../../assets/photos/avatar_2.jpg'
import testimonialImg from '../../assets/photos/IMG_3748_edit.jpg'
import { Button, createStyles } from '@mantine/core'
import { Link } from 'react-router-dom'

const splashGradient = 'linear-gradient(to top, #232323f2, #000000a3)'

const customh1SmallerThanXl = {
  h1: {
    fontSize: 'calc(22px * 2) !important',
  },
}

const useStyles = createStyles((theme) => ({
  avatarTilewrapper: {
    height: '500px',
    [theme.fn.smallerThan('sm')]: {
      height: '100%',
    },
  },
  shortAboutTile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    padding: '0 2em',
    maxWidth: '545px',
    [theme.fn.smallerThan('lg')]: {
      padding: '0 4em',
      [theme.fn.smallerThan('sm')]: {
        padding: `0 ${theme.spacing.sm}px`,
        paddingTop: `${theme.spacing.lg}px`,
      },
    },
  },
  shortPackagesTile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '1em',
    h1: {
      textAlign: 'center',
      margin: '0 0.6em',
    },
    [theme.fn.smallerThan('xl')]: customh1SmallerThanXl,
  },
  shortTestimonialsTile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    padding: '2em 4em',
    h1: {
      textAlign: 'center',
      margin: '0 0.6em',
    },
    [theme.fn.smallerThan('xl')]: {
      ...customh1SmallerThanXl,
      [theme.fn.smallerThan('sm')]: {
        padding: '0',
      },
    },
  },
  shortContactFaqsTile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '1em',
    h1: {
      textAlign: 'center',
      margin: '0 0.6em',
    },
    [theme.fn.smallerThan('xl')]: customh1SmallerThanXl,
  },
}))

const splashTextStyles = createStyles((theme) => ({
  splashTextWrapper: {
    h3: {
      [theme.fn.smallerThan('sm')] : {
        fontSize: `${theme.fontSizes.xl}px !important`
      }
    }
  },
}))

export const carouselImages: CarouselCardProps[] = [
  {
    image: `${splashGradient},url("${homeImg1}")`,
    category: '',
    title: '',
  },
  {
    image: `${splashGradient},url("${homeImg2}")`,
    category: '',
    title: '',
  },
  {
    image: `${splashGradient},url("${homeImg3}")`,
    category: '',
    title: '',
  },
  {
    image: `${splashGradient},url("${homeImg4}")`,
    category: '',
    title: '',
  },
  {
    image: `${splashGradient},url("${homeImg5}")`,
    category: '',
    title: '',
  },
]

export const toolbarImage = `url("${toolbarImg}")`

export const HomeSplashText = (): JSX.Element => {
  const { classes } = splashTextStyles()
  return (
    <div className={classes.splashTextWrapper}>
      <h3>
        Personal Trainer &<br /> Calisthenics Coach
      </h3>
      <h3>Start Healthy Habits today</h3>
      <h3 className='highlight-text-brand'>
        Become The Best Version Of
        <br /> Yourself{' '}
      </h3>
    </div>
  )
}

export const ShortAboutTile = (): JSX.Element => {
  const tileTest1: TileOpts = {
    style: 'lg-media-1',
    colour: 'bg-primary',
    accent: 'bg-tertiary',
    avatar: avatarImg,
  }

  const { classes } = useStyles()

  return (
    <div className={classes.avatarTilewrapper}>
      <Tile tileOpts={tileTest1} h={'100%'}>
        <div className={classes.shortAboutTile}>
          <h4>{"Hi, i'm Rik Mistry."}</h4>
          <p>
            My goal is to teach and inspire. I work with both men and women of all ages and
            abilities, to help you become the best version of yourself.
          </p>

          <Link to='/about' style={{ margin: '1.2em' }}>
            <Button
              variant='white'
              radius='xl'
              size='md'
              styles={{
                root: { padding: '0 14', height: 48 },
              }}
            >
              Find out more about me
            </Button>
          </Link>
        </div>
      </Tile>
    </div>
  )
}

export const ShortPackagesTile = (): JSX.Element => {
  const tileTest2: TileOpts = {
    style: 'simple-3',
    colour: 'brand-tertiary',
    accent: 'bg-tertiary',
  }

  const { classes } = useStyles()

  return (
    <Tile tileOpts={tileTest2}>
      <div className={classes.shortPackagesTile}>
        <h1>Learn more about packages and prices!</h1>
        <Link to='/packages' style={{ margin: '1.2em' }}>
          <Button
            variant='light'
            // rightIcon={<IconLink size={20} stroke={1.5} />}
            radius='xl'
            size='md'
            styles={{
              root: { padding: '0 14', height: 48 },
            }}
          >
            View Packages
          </Button>
        </Link>
      </div>
    </Tile>
  )
}

export const ShortTestimonialsTile = (): JSX.Element => {
  const tileTest2: TileOpts = {
    style: 'lg-media-2',
    colour: 'brand-primary',
    accent: 'bg-secondary',
    avatar: testimonialImg,
    avatarSize: 'xl',
    greyscaleMedia: true,
  }

  const { classes } = useStyles()

  return (
    <div className={classes.avatarTilewrapper}>
      <Tile tileOpts={tileTest2} h={'100%'}>
        <div className={classes.shortTestimonialsTile}>
          <h1>{"Don't Just take my word for it."}</h1>
          <Link to='/testimonials' style={{ margin: '1.2em', alignSelf: 'flex-start' }}>
            <Button
              variant='white'
              radius='xl'
              size='md'
              styles={{
                root: { padding: '0 14', height: 48 },
              }}
            >
              View Testimonials
            </Button>
          </Link>
        </div>
      </Tile>
    </div>
  )
}

export const ShortContactFaqsTile = (): JSX.Element => {
  const tileTest2: TileOpts = {
    style: 'simple-1',
    colour: 'brand-tertiary',
    accent: 'bg-primary',
  }

  const { classes } = useStyles()

  return (
    <Tile tileOpts={tileTest2}>
      <div className={classes.shortContactFaqsTile}>
        <h1 style={{ textAlign: 'center' }}>
          Got a question?
          <br /> Contact us now.
        </h1>
        <Link to='/contact-faqs' style={{ margin: '1.2em' }}>
          <Button
            variant='light'
            radius='xl'
            size='md'
            styles={{
              root: { padding: '0 14', height: 48 },
            }}
          >
            View Contact & FAQs
          </Button>
        </Link>
      </div>
    </Tile>
  )
}
