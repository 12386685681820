import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAccount } from '@azure/msal-react'
import { Button, createStyles, Flex } from '@mantine/core'
import { getOrders } from '../../helpers/auth-api.helper'
import { IconChevronRight } from '@tabler/icons'

const useStyles = createStyles((theme) => ({
  orderList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing.xl,

    [theme.fn.smallerThan('sm')]: {
      padding: 0,
    },
  },

  orderRowWrapper: {
    backgroundColor: theme.colors[theme.primaryColor][6],
    margin: theme.spacing.lg,
    width: '60%',
    borderRadius: theme.spacing.sm,
    padding: theme.spacing.lg,
    position: 'relative',

    [theme.fn.smallerThan('sm')]: {
      width: 'auto',
      margin: theme.spacing.md,
      padding: theme.spacing.md,
    },
  },

  orderRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing.lg,

    [theme.fn.smallerThan('sm')]: {
      gap: theme.spacing.sm,
    },
  },

  orderAvatarWrapper: {
    width: '50px',
    height: '50px',
  },

  orderAvatarContent: {
    width: '50px',
    height: '50px',
    background: 'black',
    borderRadius: '30px',
    borderStyle: 'solid',
    borderWidth: '2px',
    overflow: 'overlay',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    h1: {
      fontSize: `${theme.fontSizes.md}px !important`,
      margin: '0',
    },
  },

  orderId: {
    bottom: 0,
    right: 0,
    marginRight: theme.spacing.xs * 2,
    marginBottom: theme.spacing.xs,
    fontSize: '14px',
    color: theme.colors[theme.primaryColor][4],
    position: 'absolute',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  totalCount: {
    color: theme.colors[theme.primaryColor][6],
  },

  viewDetailsButton: {
    margin: '1.2em',
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  mobileViewDetailsButton: {
    margin: '1.2em 0',
    padding: theme.spacing.sm,
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  rowHeading: {
    [theme.fn.smallerThan('sm')]: {
      fontSize: theme.fontSizes.md,
    },
  },

  rowSubHeading: {
    [theme.fn.smallerThan('sm')]: {
      fontSize: theme.fontSizes.sm,
    },
  },

  mobileRowLink: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    color: 'inherit',
    textDecoration: 'none',
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
}))

export const OrderList = (): JSX.Element => {
  const { classes } = useStyles()
  const isMounted = useRef(false)

  const user = useAccount()
  // const userId = user?.localAccountId

  const [error, setError] = useState<string | null>(null)
  // TODO: Add OrderListVm
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [orders, setOrders] = useState<any[]>([])

  useEffect(() => {
    if (!user) {
      console.log('user is not present')
      return
    }

    if (isMounted.current) {
      console.log('component mounted already')
      return
    }
    isMounted.current = true

    getOrders(user.localAccountId)
      .then((res) => {
        return res.json().then((ordersRes) => {
          if (!res.ok) {
            console.log(ordersRes)
            throw new Error(ordersRes.error || ordersRes.message || 'Failed to get orders')
          }

          return ordersRes
        })
      })
      .then((data) => setOrders(data.orders))
      .catch((e) => setError(e.error || e.message || e))
  }, [user])

  return (
    <div className={classes.orderList}>
      {error}
      {orders.map((order) => (        
          <div key={order.id} className={classes.orderRowWrapper}>
            <Link
          key={order.id}
          className={classes.mobileRowLink}
          to={`/order-details?orderId=${order.id}`}
        ></Link>
            <div className={classes.orderId}>Order ID: {order.id}</div>

            <div className={classes.orderRow}>
              <div className={classes.orderAvatarWrapper}>
                <div className={classes.orderAvatarContent}>
                  <h1>{order.productTitle[0]}</h1>
                </div>
              </div>

              <Flex direction={'column'} w={'100%'}>
                {/* Single product currently only supported */}
                <h4 className={classes.rowHeading} style={{ margin: '0' }}>
                  {order.productTitle}
                </h4>
                <p className={classes.rowSubHeading}>
                  {order.status} on {order.updatedOn ?? order.createdOn}
                </p>
              </Flex>

              <Link to={`/order-details?orderId=${order.id}`}>
                <Button
                  className={classes.viewDetailsButton}
                  variant='white'
                  radius='xl'
                  size='md'
                  styles={{
                    root: { padding: '0 14', height: 48 },
                  }}
                >
                  View details
                </Button>

                <Button
                  className={classes.mobileViewDetailsButton}
                  variant='white'
                  radius='xl'
                  size='md'
                  styles={{
                    root: { height: 48 },
                  }}
                >
                  <IconChevronRight />
                </Button>
              </Link>
            </div>
          </div>
      ))}
      <p className={classes.totalCount}>Total: {orders.length}</p>
    </div>
  )
}
