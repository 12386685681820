import { createStyles } from '@mantine/core'
import { ReactNode } from 'react'

import { TileOpts } from './tile'

export const TileContent = (props: { tileOpts: TileOpts; children: ReactNode }) => {
  const isLargeAvatarTile = !!props.tileOpts?.avatar && props.tileOpts?.avatarSize === 'lg'

  const { classes } = createStyles((theme) => ({
    lgAvatar: {
      margin: `0 ${theme.spacing.md}px`,
    },
  }))()
  const contentClass = isLargeAvatarTile ? classes.lgAvatar : ''

  return <div className={`tile-content ${contentClass}`}>{props.children}</div>
}
