import React, { useLayoutEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, useLocation } from 'react-router-dom'

import { MantineProvider } from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications'

import { App } from './App'
import { initMsalClient } from './helpers/msal.helper'
import { Theme } from './shared/mantine.theme'
import './styles/app.scss'
import { initConfig } from './config'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { getGlobalState } from './state/state'

const initializer = () => {
  const config = getGlobalState('config')
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: config?.appInsightsConnectionString,
      /* ...Other Configuration Options... */
    },
  })
  appInsights.loadAppInsights()
  appInsights.trackPageView()
}

initConfig().then(() => initializer())
const msalInstance = initMsalClient()

const container = document.getElementById('root')

if (container == null) throw new Error('root not found!')

const root = createRoot(container)
// root.render(<App />);

const Wrapper = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  return children
}

root.render(
  <React.StrictMode>
    {/* <meta name='viewport' content='initial-scale=1, width=device-width' /> */}
    <meta
      name='viewport'
      content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
    />

    <BrowserRouter>
      <Wrapper>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={Theme}>
          <NotificationsProvider>
            <App instance={msalInstance} />
          </NotificationsProvider>
        </MantineProvider>
      </Wrapper>
    </BrowserRouter>
  </React.StrictMode>,
)
