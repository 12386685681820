import { Stage1, Stage2, Stage3, Stage4 } from './misterio-stage.constants'

export const getStage = (stageNum: number) => {
  switch (stageNum) {
    case 1:
      return Stage1

    case 2:
      return Stage2

    case 3:
      return Stage3

    case 4:
      return Stage4

    default:
      return null
  }
}
