import {
  Button,
  createStyles,
  Group,
  SimpleGrid,
  Textarea,
  TextInput,
  Title,
  Text,
  Flex,
} from '@mantine/core'
import { useForm } from '@mantine/form'

import { Splash } from '../../components/splash'
import img1 from '../../assets/large_mof_background.svg'
import { useState } from 'react'
import { Faqs } from './faqs'
import { AngularStyle } from '../../components/angular-style'

const API_URL_HOST = process.env.NODE_ENV === 'development' ? 'http://localhost:7071' : window.location.origin

const useStyles = createStyles((theme) => ({
  contactWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  contactFaqItem: {
    backgroundColor: theme.colors[theme.primaryColor][9],
    borderRadius: theme.radius.md,
    padding: theme.spacing.xl,

    [theme.fn.largerThan('sm')]: {
      width: '65%',
      padding: theme.spacing.lg,
    },

    [theme.fn.largerThan('lg')]: {
      width: '55%',
    },

    [theme.fn.smallerThan('sm')]: {
      width: '100%',
      padding: theme.spacing.sm,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: 300,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },

  form: {
    backgroundColor: theme.colors[theme.primaryColor][8],
    padding: theme.spacing.lg,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,

    [theme.fn.smallerThan('sm')]: {
      padding: theme.spacing.xs,
    },
  },

  social: {
    color: theme.white,

    '&:hover': {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.black,
    borderColor: theme.colors.gray[4],
    color: theme.white,

    '&::placeholder': {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.white,
  },

  control: {
    backgroundColor: theme.colors[theme.primaryColor][6],
  },

  submitSuccess: {
    padding: '12px',
    fontSize: '14pt',
  },

  submitError: {
    padding: '12px',
    fontSize: '14pt',
    color: 'red',
  },

  splashText: {
    h1: {
      [theme.fn.smallerThan('sm')]: {
        fontSize: `${theme.fontSizes.lg * 2}px !important`,
      },
    },
    justifyContent: 'start',
    height: '100%',
  },
}))

export const ContactFaqs = (): JSX.Element => {
  const { classes } = useStyles()

  const [data, setData] = useState('')

  const [alreadyContacted, setAlreadyContacted] = useState(false)
  const [sending, setSending] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
    },
  })

  async function submit(body: string) {
    setAlreadyContacted(true)
    setSending(true)

    try {
      await (
        await fetch(`${API_URL_HOST}/api/send-email`, {
          method: 'POST',
          body,
          headers: { 'Content-Type': 'application/json' },
        })
      ).json()

      const successMsg =
        'Thank you for your contacting me. I will try to get back to you as soon as possible.'
      setData(successMsg)
    } catch (e) {
      console.error('An error has occurred', e)

      const failMsg =
        'Sorry! There was an issue trying to contact me here. Please try again later, or alternatively use my contact details to get in touch.'
      setData(failMsg)

      setSubmitError(true)
    }

    setSending(false)
  }

  return (
    <div className='contact-faqs'>
      <Splash height={'calc(100vh - 56px)'} backgroundSrc={`url(${img1})`}>
        <div className={classes.splashText}>
          <h1 style={{ textShadow: '5px 5px black', textAlign: 'center' }}>Contact & FAQs</h1>
        </div>
      </Splash>

      <AngularStyle
        className='test'
        colour='bg-primary'
        style={3}
        side={'left'}
        size={'sm'}
        height={'50px'}
        width={'100%'}
      ></AngularStyle>

      <Flex
        p={{ base: 'md', md: 'lg', lg: 'xl' }}
        gap={{ base: 'sm', md: 'lg', lg: 'xl' }}
        justify='center'
        align='flex-start'
        direction='column'
      >
        <div className={classes.contactWrapper}>
          <SimpleGrid
            className={classes.contactFaqItem}
            cols={2}
            spacing={'md'}
            breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
          >
            <div>
              <Title className={classes.title}>Contact us</Title>
              <Text className={classes.description} mt='sm' mb={30}>
                Leave your email and we will try to get back to you within 48 hours
              </Text>
            </div>
            <form
              className={classes.form}
              onSubmit={form.onSubmit((values) => submit(JSON.stringify(values, null, 2)))}
            >
              <TextInput
                label='Name'
                required
                mt='md'
                {...form.getInputProps('name')}
                classNames={{ input: classes.input, label: classes.inputLabel }}
              />
              <TextInput
                label='Email'
                required
                {...form.getInputProps('email')}
                classNames={{ input: classes.input, label: classes.inputLabel }}
              />
              <TextInput
                label='Contact number (optional)'
                mt='md'
                {...form.getInputProps('phone')}
                classNames={{ input: classes.input, label: classes.inputLabel }}
              />
              <Textarea
                required
                label='Message'
                placeholder='Write your message here...'
                minRows={4}
                mt='md'
                {...form.getInputProps('message')}
                classNames={{ input: classes.input, label: classes.inputLabel }}
              />

              <p className={submitError ? classes.submitError : classes.submitSuccess}>{data}</p>

              <Group position='right' mt='md'>
                <Button className={classes.control} type='submit' disabled={alreadyContacted}>
                  {sending ? 'Sending...' : 'Send message'}
                </Button>
              </Group>
            </form>
          </SimpleGrid>
        </div>

        <div className={classes.contactWrapper}>
          <div className={classes.contactFaqItem}>
            <Faqs></Faqs>
          </div>
        </div>
      </Flex>

      <AngularStyle
        className='test'
        colour='brand-secondary'
        style={3}
        side={'right'}
        size={'sm'}
        height={'50px'}
        width={'100%'}
      ></AngularStyle>
    </div>
  )
}
