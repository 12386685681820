import { Tile, TileOpts } from '../components/tile'

import img1 from '../assets/pages/about/IMG_3815_2.jpg'
import img2 from '../assets/pages/about/IMG_3884.jpg'
import img3 from '../assets/pages/about/IMG_3800.jpg'

import { createStyles } from '@mantine/core'
import { Splash, splashClassProps } from '../components/splash'
import { AngularStyle } from '../components/angular-style'

const useStyles = createStyles((theme) => ({
  splash: {
    ...splashClassProps,
    [theme.fn.smallerThan('sm')]: {
      backgroundPositionX: '-200px !important',
    },
  },

  intro: {
    padding: '2em 8em',
    [theme.fn.smallerThan('lg')]: {
      padding: '2em 6em',

      [theme.fn.smallerThan('sm')]: {
        padding: theme.spacing.sm,
      },
    },
  },

  whyMofWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.colors.dark[9],
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  whyMofTextSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.xl,
    [theme.fn.smallerThan('sm')]: {
      padding: theme.spacing.sm * 2, // matches lgAvatar class used for md-media-2
    },
    flex: '4.5 1 0%',
    gap: theme.spacing.md,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  whyMofHeading: {},

  whyMofContent: {},

  whyMofImage: {
    flex: '1 0 0%',
    overflow: 'auto',
    height: 'auto',
    minHeight: 'auto',
    minWidth: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const About = (): JSX.Element => {
  const aboutTileOpts: TileOpts = {
    style: 'md-media-1',
    colour: 'bg-black',
    accent: 'brand-secondary',
    greyscaleMedia: false,
    avatar: img1,
    avatarSize: 'xl',
  }

  const { classes } = useStyles()

  const aboutP1 =
    // eslint-disable-next-line quotes
    "Okay Introductions first. My name is Rik and I'm a Fitness coach specialising in Calisthenics and weight management. I like handstands, chicken wings and wearing sunglasses."

  const aboutP2 =
    // eslint-disable-next-line quotes
    'I have been in the fitness industry for close to 10 years, having helped people start their fitness journey, to mastering a muscle up, to running their first 5K.'

  const aboutP3 =
    // eslint-disable-next-line quotes
    "I believe that exercise in whatever form helps not only to keep you physically, but mentally, socially and spiritually healthy. Being healthy and in shape is not about deadlifting 300kg, having a six pack or 5% body fat. It's about not running out of breath when playing with your kids, walking up the stairs pain free, looking at yourself in the mirror and being able to smile and be happy with how you look and feel."

  const aboutP4 =
    // eslint-disable-next-line quotes
    "I've always been a skinny guy, having being bullied, suffered with depression and a lack of self confidence. Never winning an arm wrestling contest . And it took me a long time to overcome a lot of these challenges. However exercise has helped me to overcome these obstacles and this is what I want to help you with. "

  // eslint-disable-next-line quotes
  const whyP1 =
    'I work with men and women of all ages and abilities. My goal is to teach and inspire, to help you become the best version of yourself.'

  const whyP2 =
    // eslint-disable-next-line quotes
    "Unlike traditional gym workouts I focus on exercises that improve and perfect the body’s ability to do what it's meant to do. MOVE, RUN, JUMP, PUSH, PULL! This is functional training.  Mastering movements in order to build toward an ultimate goal as opposed to just adding more weight and more reps is the most EFFICIENT, FUN and COOLEST way to get into shape. If your goal is to get into the best possible shape of your life in the least amount of time, this is the way forward."

  const whyP3 =
    // eslint-disable-next-line quotes
    'Whether you are nervous about starting and keep putting it off, stuck in a rut with your program, or want to try something new. I am here to help you get to your goal.'

  return (
    <div className='about-page'>
      <Splash
        height={'calc(100vh - 56px)'}
        backgroundSrc={`url(${img2})`}
        className={classes.splash}
      >
        <h1 style={{ textShadow: '5px 5px black' }}>About</h1>
      </Splash>
      <div className='about-tile'>
        <Tile tileOpts={aboutTileOpts} mh={200}>
          <p className={classes.intro}>
            {aboutP1}
            <br />
            <br />
            {aboutP2}
            <br />
            <br />
            {aboutP3}
            <br />
            <br />
            {aboutP4}
          </p>
        </Tile>

        <div className={classes.whyMofWrapper}>
          <div>
            <AngularStyle
              className='test'
              colour='bg-secondary'
              style={3}
              side={'left'}
              size={'sm'}
              height={'50px'}
              width={'100%'}
            ></AngularStyle>

            <div className={classes.whyMofTextSection}>
              <div className={classes.whyMofHeading}>
                <h3>Why Mistry Of Fitness?</h3>
              </div>

              <div className={classes.whyMofContent}>
                <p>
                  {whyP1}
                  <br />
                  <br />
                  {whyP2}
                  <br />
                  <br />
                  {whyP3}
                </p>
              </div>
            </div>
          </div>

          <div className={classes.whyMofImage}>
            <img
              src={img3}
              style={{
                alignSelf: 'center',
                height: '100%',
                overflow: 'auto',
                objectFit: 'cover',
              }}
            ></img>
          </div>
        </div>
      </div>
    </div>
  )
}
