import {
  setError,
  setLoadingUserData,
  setUserId,
  setUserProductIds,
  getGlobalState,
  useGlobalState,
} from '../state/state'
import { getUserProducts } from './auth-api.helper'

export const loginUser = async (userId: string, force?: boolean) => {
  const loggedInUserId = getGlobalState('userId')

  if (!force && loggedInUserId) return

  setLoadingUserData(true)

  setUserId(userId)
  await loadUserProducts(userId)

  setLoadingUserData(false)
}

export const loadUserProducts = async (userId: string) => {
  try {
    const res = await getUserProducts(userId)
    
    if (!res.ok) {
      let message
      try {
        const error = await res.json()
        message = error?.message

        console.error(error)
      } catch {
        console.log('Failed to parse failed response for get-user-products')

        message = 'Unknown response'
      }

      throw new Error(`Request failed. [${res.status}] ${message}`)
    }

    const { userProducts } = await res.json()

    setUserProductIds(userProducts.map((up: { productId: string }) => up.productId))
  } catch (e: unknown) {
    console.log('Failed to loadUserProducts')

    setError(`Failed to load user products. Error: ${e}`)
  }
}

export const hasUserProduct = (productId: string) => {
  const [userProductIds] = useGlobalState('userProductIds')

  return userProductIds?.some((id) => id === productId)
}

export const logoutUser = () => {
  setUserId(undefined)
  setUserProductIds([])
}
