import { getGlobalState, setConfig, setError } from './state/state'

const API_URL_HOST =
  process.env.NODE_ENV === 'development' ? 'http://localhost:7071' : window.location.origin

const DefaultConfig: Partial<Config> = {
  social: {
    instagram: 'https://www.instagram.com/mistryoffitness/',
    youtube: '',
    facebook: 'https://www.facebook.com/Mistryoffitness',
  },
}

export type Config = {
  social: {
    instagram: string
    youtube: string
    facebook: string
  }
  stripeApiKey: string
  misterioProductPrice: string
  appInsightsConnectionString: string
}

export async function initConfig() {
  const config = getGlobalState('config')

  if (config) return

  const res = await fetch(`${API_URL_HOST}/api/config`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })

  if (!res.ok) {
    const message = 'Failed to retrieve config from server. Please refresh to try again.'

    setError(message)
    throw new Error(message)
  }

  const serverConfig = await res.json()

  const latestConfig = {
    ...DefaultConfig,
    ...serverConfig,
  }

  setConfig(latestConfig)
}
