import { useNavigate, Link } from 'react-router-dom'

import { createStyles, Flex, Grid } from '@mantine/core'

import { AngularStyle } from '../../components/angular-style'

import misterioLogo from '../../assets/misterio_smoky_logo.png'
import stage1Icon from '../../assets/misterio/stages/stage_1.jpg'
import stage2Icon from '../../assets/misterio/stages/stage_2.jpg'
import stage3Icon from '../../assets/misterio/stages/stage_3.jpg'
import stage4Icon from '../../assets/misterio/stages/stage_4.jpg'
import stage5Icon from '../../assets/misterio/stages/stage_5.jpg'
import { DocumentButton } from '../../shared/document-button'
import { FolderButton } from '../../shared/folder-button'
import { hasUserProduct } from '../../helpers/user.helper'
import { MISTERIO_PRODUCT_ID } from '../../products/misterio-project-product'
import { useEffect } from 'react'
import { useGlobalState } from '../../state/state'
import { manualDocument, workoutDocument } from './misterio.helper'

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing.md,
    margin: '0 350px',

    [theme.fn.largerThan(theme.breakpoints.xl * 1.5)]: {
      margin: '0 600px',
    },

    [theme.fn.smallerThan('xl')]: {
      margin: '0 180px',

      [theme.fn.smallerThan('lg')]: {
        margin: '0 140px',

        [theme.fn.smallerThan('md')]: {
          margin: '0 60px',

          [theme.fn.smallerThan('sm')]: {
            margin: '0 16px',
          },
        },
      },
    },
  },

  misterioImg: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },

  avatarWrapper: {
    width: '200px',
    height: '200px',
  },

  avatarContent: {
    width: '100%',
    height: '100%',
    background: 'black',
    borderRadius: '150px',
    borderStyle: 'solid',
    borderWidth: '4px',
    overflow: 'overlay',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    h1: {
      fontSize: `${theme.fontSizes.md}px !important`,
      margin: '0',
    },
  },

  folderWrapper: {
    padding: '0 14px',

    ['@media (min-width: 1600px)']: {
      padding: '0 5%',
    },
    
    [theme.fn.smallerThan('xl')] : {
      padding: '0 3%',

      [theme.fn.smallerThan('lg')] : {
        padding: '0 2%',

        [theme.fn.smallerThan('md')] : {
          padding: '0 1%',

          [theme.fn.smallerThan('sm')] : {
            padding: '0 20px',
            [theme.fn.smallerThan('sm')] : {
              padding: '0 8px'
            }
          },
        },
      }
    }
  },

  folderLink: {
    color: 'inherit',
    textDecoration: 'none',
  },

  topWrapper: {
    flexDirection: 'row',
    alignItems: 'center',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',

      h1: {
        fontSize: `${theme.fontSizes.lg * 2}px !important`,
      },
    },
  },
}))

export const Misterio = (): JSX.Element => {
  const [loadingUserData] = useGlobalState('loadingUserData')
  const hasMisterio = hasUserProduct(MISTERIO_PRODUCT_ID)
  const navigate = useNavigate()

  useEffect(() => {
    if (!loadingUserData && !hasMisterio) {
      navigate('/') // redirect to home
    }
  }, [hasMisterio, loadingUserData])

  const { classes } = useStyles()

  const documents = [
    manualDocument(),
    workoutDocument()
  ].map((file) => <DocumentButton key={file.name} {...file} />)

  const stages = [
    { stage: 1, src: stage1Icon },
    { stage: 2, src: stage2Icon },
    { stage: 3, src: stage3Icon },
    { stage: 4, src: stage4Icon },
    {
      stage: 5,
      src: stage5Icon,
      imgText: 'Coming soon!',
      blur: true,
    },
  ].map((file, i) => (
    <Grid.Col className={classes.folderWrapper} key={i} span={1}>
      {!('blank' in file) && (
        <Link className={classes.folderLink} to={'/misterio/stage/' + file.stage}>
          <FolderButton {...file} name={`Stage ${file.stage}`} />
        </Link>
      )}
    </Grid.Col>
  ))

  return (
    <>
      <AngularStyle
        colour='brand-secondary'
        style={3}
        side={'left'}
        size={'sm'}
        height={'50px'}
        width={'100%'}
      ></AngularStyle>

      <div className={classes.wrapper}>
        {/* <h3>Misterio Programme</h3> */}

        <Flex className={classes.topWrapper} gap={'xl'} w='100%' justify={'center'} p={'24px 0'}>
          <div className={classes.avatarWrapper}>
            <div className={classes.avatarContent}>
              <img className={classes.misterioImg} src={misterioLogo}></img>
            </div>
          </div>

          <Flex w={'60%'} direction={'column'} gap={'md'} justify={'center'} align={'start'}>
            <h1 style={{ margin: 0 }}>Misterio Programme</h1>
            <div>
              <p>A functional training programme designed to get you in the best shape of your life.</p>
              <p style={{ fontSize: '12px' }}>By Rik Mistry</p>
            </div>
          </Flex>
        </Flex>

        <Flex gap={48}>{documents}</Flex>

        <Grid h={'100%'} columns={5} grow gutter={'xs'} justify='center' align={'center'}>
          {stages}
        </Grid>
      </div>
    </>
  )
}
