import { createStyles, Paper, Title } from '@mantine/core'
import { Carousel } from '@mantine/carousel'
import Autoplay from 'embla-carousel-autoplay';
import { useRef } from 'react';

export interface CarouselCardProps {
  image: string
  title: string
  category: string
}

const useStyles = createStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    color: theme.white,
    lineHeight: 1.2,
    fontSize: 32,
    marginTop: theme.spacing.xs,
  },

  category: {
    color: theme.white,
    opacity: 0.7,
    fontWeight: 700,
    textTransform: 'uppercase',
  },

  carousel: {
    height: '100%',
    width: '100%',
  },
}))

function Card({ image }: CarouselCardProps) {
  const { classes } = useStyles()

  return (
    <Paper shadow='md' p='xl' radius={0} sx={{ backgroundImage: image }} className={classes.card}>
      <div>
        {/* <Text className={classes.category} size='xs'>
          {category}
        </Text> */}
        <Title order={3} className={classes.title}>
          {/* {title} */}
        </Title>
      </div>
    </Paper>
  )
}

export function CardsCarousel(props: { slides: CarouselCardProps[]; overlayText?: string }) {
  // const theme = useMantineTheme()
  const { classes } = useStyles()
  // const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`)

  const slides = props.slides.map((item, i) => (
    <Carousel.Slide key={'slide_' + i}>
      <Card {...item} />
    </Carousel.Slide>
  ))

  const autoplay = useRef(Autoplay({ delay: 6000 }));

  return (
    <Carousel
      slideSize='100%'
      height={'100%'}
      breakpoints={[{ maxWidth: 'sm', slideSize: '100%', slideGap: 2 }]}
      slideGap='xl'
      align='start'
      // slidesToScroll={mobile ? 1 : 2}
      classNames={{
        root: classes.carousel,
      }}
      withIndicators
      plugins={[autoplay.current]}
    >
      {slides}
    </Carousel>
  )
}
