import promiseRetry from 'promise-retry'

import {
  MISTERIO_PRODUCT_SHORT_DESC,
  MISTERIO_PRODUCT_TITLE,
} from '../products/misterio-project-product'
import { getAccessToken } from './msal.helper'

const API_URL_HOST =
  process.env.NODE_ENV === 'development' ? 'http://localhost:7071' : window.location.origin

type ApiAuthenticatedRequest = {
  functionName: string
  fnQuery?: URLSearchParams
  fnMethod: string
  fnBody?: unknown
  fnHeaders: HeadersInit
}

async function apiAuthenticatedRequest({
  functionName,
  fnQuery,
  fnMethod,
  fnBody,
  fnHeaders,
}: ApiAuthenticatedRequest) {
  const body = JSON.stringify(
    {
      fnQuery: fnQuery?.toString(),
      fnMethod,
      fnBody,
      fnHeaders,
    },
    null,
    2,
  )

  return await fetch(`${API_URL_HOST}/api/authenticated?function=${functionName}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
}

export async function getOrderCheckoutIntent(params: {
  orderId: string
  productId: string
  discountCode?: string
}) {
  const { orderId, productId, discountCode } = params
  const fnHeaders = await getAuthApiFnHeaders()
  const fnQuery = getAuthQuery({ orderId })

  const fnBody = {
    productId,
    quantity: 1,
    discountCode,
    productTitle: MISTERIO_PRODUCT_TITLE,
    productDesc: MISTERIO_PRODUCT_SHORT_DESC,
  }

  return apiAuthenticatedRequest({
    functionName: 'order-checkout',
    fnBody,
    fnHeaders,
    fnMethod: 'POST',
    fnQuery,
  })
}

export async function orderIntentUpdate(params: {
  productId: string
  discountCode?: string
  paymentIntentId: string
}) {
  const { productId, paymentIntentId, discountCode } = params
  const fnHeaders = await getAuthApiFnHeaders()

  const fnBody = {
    productId,
    quantity: 1,
    discountCode,
    paymentIntentId,
  }

  return apiAuthenticatedRequest({
    functionName: 'order-checkout-update',
    fnBody,
    fnHeaders,
    fnMethod: 'POST',
  })
}

export async function getUserProducts(userId: string) {
  const fnHeaders = await getAuthApiFnHeaders()
  const fnQuery = getAuthQuery({ userId })

  return apiAuthenticatedRequest({
    functionName: 'get-user-products',
    fnHeaders,
    fnMethod: 'GET',
    fnQuery,
  })
}

export async function getOrder(orderId: string) {
  const fnHeaders = await getAuthApiFnHeaders()
  const fnQuery = getAuthQuery({ orderId })

  return promiseRetry(
    (retry, attempt) => {
      if (attempt > 1) console.log('Trying to get order again...', attempt)

      return (
        apiAuthenticatedRequest({
          functionName: 'get-order',
          fnHeaders,
          fnMethod: 'GET',
          fnQuery,
        })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((res: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return res.json().then((orderRes: any) => {
              if (!res.ok) {
                console.log(orderRes)
                throw new Error(orderRes.error || orderRes.message || 'Failed to get orders')
              }

              return orderRes
            })
          })
          .catch(retry)
      )
    },
    { retries: 3, maxRetryTime: 6000 },
  )
}

export async function getOrders(userId: string) {
  const fnHeaders = await getAuthApiFnHeaders()
  const fnQuery = getAuthQuery({ userId })

  return apiAuthenticatedRequest({
    functionName: 'get-orders',
    fnHeaders,
    fnMethod: 'GET',
    fnQuery,
  })
}

export async function getDocument(documentId: string) {
  const fnHeaders = await getAuthTokenHeader()
  const fnQuery = getAuthQuery({ documentId })

  return (
    apiAuthenticatedRequest({
      functionName: 'get-document',
      fnHeaders,
      fnMethod: 'GET',
      fnQuery,
    })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return res.blob().then((orderRes: any) => {
          if (!res.ok) {
            console.log(orderRes)
            throw new Error(orderRes.error || orderRes.message || 'Failed to get document')
          }

          return orderRes
        })
      })
  )
}

export async function getAuthTokenHeader() {
  const accessToken = await getAccessToken()

  return {
    Authorization: `Bearer ${accessToken}`,
  }
}

export async function getAuthApiFnHeaders() {
  const authTokenHeader = await getAuthTokenHeader()

  return {
    ...authTokenHeader,
    'Content-Type': 'application/json',
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getAuthQuery(obj: any) {
  return new URLSearchParams({
    ...obj,
  })
}
