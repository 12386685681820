import { createStyles } from '@mantine/core'

export interface Video {
  name: string
  src: string
  desc?: string
  thumbnail?: string
}

const useStyles = createStyles(() => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  videoPlayer: {
    width: '100%',
    height: '100%'
  }
}))

export const VideoPlayer = (props: { video: Video }) => {
  const { video } = props
  const { classes } = useStyles()

  return (
    <div className={classes.wrapper}>
      <span>{video.desc}</span>
      <iframe
      className={classes.videoPlayer}
        src={video.src}
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowFullScreen
      ></iframe>
    </div>
  )
}
