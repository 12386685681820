/* eslint-disable quotes */
import { Splash, splashClassProps } from '../components/splash'
import { Tile, TileOpts } from '../components/tile'

import img1 from '../assets/pages/testimonials/IMG_3688.jpg'
import { createStyles, Flex, Grid, MediaQuery } from '@mantine/core'

import brookeImg from '../assets/pages/testimonials/Brooke.jpg'
import helenImg from '../assets/pages/testimonials/Helen.jpg'
import ioanaImg from '../assets/pages/testimonials/Ioana.jpg'
import megImg from '../assets/pages/testimonials/Meg.jpg'
import pennyImg from '../assets/pages/testimonials/Penny.jpg'
import reeceImg from '../assets/pages/testimonials/Reece.jpg'
import { IconUser } from '@tabler/icons'
import { MofCard } from '../components/card'

const useStyles = createStyles((theme) => ({
  splash: {
    ...splashClassProps,
    h1: {
      textShadow: '5px 5px black',
    },
  },

  heading: {
    padding: `${theme.spacing.lg}px 0 !important`,
    [theme.fn.smallerThan('sm')]: {
      padding: `${theme.spacing.sm}px !important`,
    },

    h2: {
      margin: '60px 0',
      [theme.fn.smallerThan('xl')]: {
        margin: `${theme.spacing.md}px 0`,
        fontSize: theme.fontSizes.xl,
      },
      [theme.fn.smallerThan('sm')]: {
        margin: `${theme.spacing.sm}px 0`,
        fontSize: theme.fontSizes.lg,
      }
    }
  },

  testimonialText: {
    height: '350px',
    overflow: 'auto',
    // paddingTop: theme.spacing.xl,
    [theme.fn.smallerThan('sm')]: {
      height: '250px',
      fontSize: theme.fontSizes.sm,
    },
  },

  testimonialImgContainer: {
    height: '420px',
    width: '100%',
    [theme.fn.smallerThan('sm')]: {
      height: '200px',
    },
  },

  testimonialImg: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
}))

export const Testimonials = (): JSX.Element => {
  const testimonialTileOpts: TileOpts = {
    style: 'simple-2',
    colour: 'bg-primary',
    accent: 'brand-tertiary',
  }

  const { classes } = useStyles()

  const testimonials: { text: string; by: string; imgSrc?: string; imgPos?: string }[] = [
    {
      text: "Rik is by far the best PT I've worked with! Since following his training and nutritional programme for the past 6 months, I have made more progress towards my goals than I have in the last 15 years of going to the gym & training with other PT's. Under Rik's guidance, I have increased my strength & muscle mass, and dropped my body fat percentage to the lowest it's ever been, and I am now over 40. Rik has helped me understand that health & fitness extends far beyond 'diet & exercise' but has mental, physical, spiritual & social aspects, which must be addressed to achieve lasting results. Not only has he improved my relationship with food, but his knowledge and advice has allowed me to train around injuries & even overcome them through rehab exercises. Rik is passionate about his work, and full of energy and encouragement; which makes training with him fun & exhilarating. He's always patient when teaching something new; and just a message away for that extra motivation or advice whenever needed. With the increased confidence and strength I've gained from his training programme, I am now super-excited to start my calisthenics journey with him. I highly recommend him!",
      by: 'Kiran',
    },
    {
      text: 'I thoroughly recommend Rik as a personal trainer. He has given me the incentive, confidence and ability to reach my personal goals. I am always learning and finding better ways to improve because of his guidance, enthusiasm and expertise. He never gives up on a challenge and has taught me there is always a way around a difficulty. Thank you for your help, always. You have made me feel like I can do better than I believed.',
      by: 'Helen',
      imgSrc: `${helenImg}`,
    },
    {
      text: "I was scared of the gym so I decided to give PT sessions a go to build some confidence. Having not exercised before I was worried that I'd want to give up quickly, that it would be too much of a commitment and that I'd find it too hard. My concerns disappeared immediately after my first session with Rik. Every session was fun, relaxed and motivating all at the same time! I really looked forward to attending and booked extra sessions when I could. I'm grateful for his help and support because without it I would have remained a couch potato!!",
      by: 'Penny',
      imgSrc: `${pennyImg}`,
      imgPos: 'top'
    },

    {
      text: `I highly recommend Rik as a PT. He helped me change my life for the better in terms of lifestyle, eating and fitness. Rik makes sure that the workouts are varied, interesting, challenging but fun and rewarding. The results speak for themselves. He helped me build my strength, my confidence, discipline and stamina throughout the sessions. Never imagined I could do some workouts, but with Rik’s support and encouragement, I smashed them.
        Rik is reliable, friendly, easy to communicate with, knowledgeable and funny. He is quick to make modifications based on my needs and  tells me when I could improve a posture. His explanations are clear and easy to follow. Investing into my health and choosing Rik as my PT definitely was the best decision I could have made`,
      by: 'Ioana',
      imgSrc: `${ioanaImg}`,
      imgPos: 'center 36%'
    },
    {
      text: `Training with Rik not only allowed me to increase  my mobility, flexibility and strength, but his fully personalised workout plans gave me an amazing routine for my training outside of our 1-2-1 sessions. I’d recommend Rik as a coach to anybody from beginner level all the way to athletes training to compete.`,
      by: 'Reece',
      imgSrc: `${reeceImg}`,
      imgPos: 'top'
    },
    {
      text: `Training with Rik has made a big difference for me. I feel much stronger, and my back pain has gone down a lot. What's awesome is that Rik is always there to help, even late at night. Now I can work out without pain, enjoy my food, and he makes training really fun. Plus, I've noticed visible changes in my body. Super happy with the progress and Rik's support.`,
      by: 'Brooke',
      imgSrc: `${brookeImg}`,
      imgPos: '0 -55px'
    },
    {
      text: `Rik has been my personal trainer for the last 18 months, and I couldn't be happier with the results. His personalized approach to fitness has helped me achieve several of my goals. Not only have I seen significant improvements in my strength and endurance, but I've also gained a better understanding of how to maintain a healthy lifestyle, as well as the importance of a healthy mind too. His dedication, expertise, and encouragement have truly made a difference in my calisthenics journey. I couldn't recommend Rik enough for anyone looking to take their fitness to the next level.`,
      by: 'Meg',
      imgSrc: `${megImg}`,
    },
  ]

  return (
    <div>
      <Splash
        height={'calc(100vh - 56px)'}
        backgroundSrc={`url(${img1})`}
        className={classes.splash}
      >
        <h1>Testimonials</h1>
      </Splash>

      <Tile tileOpts={testimonialTileOpts}>
        <Flex className={classes.heading} direction={'column'} gap={'md'}>
          <h2 style={{ textAlign: 'center' }}>
            Working on creating a healthier lifestyle and becoming physically, mentally
            <MediaQuery smallerThan={'sm'} styles={{ display: 'none' }}>
              <br />
            </MediaQuery>
            &nbsp;and spiritually better is by far one of the best things you can do for yourself.
            <MediaQuery smallerThan={'sm'} styles={{ display: 'none' }}>
              <br />
            </MediaQuery>
            &nbsp;And with my help you will be able to get there faster.
            <br />
            <br />
            But {"don't"} just take my word for it.
          </h2>

          <Grid gutter={'md'} gutterMd={'xl'}>
            {testimonials.map((t) => (
              <Grid.Col md={12} lg={6} key={t.by}>
                <Flex direction={'column'} style={{ flex: 1 }} align='center'>
                  <MofCard>
                    <Flex direction={'column'} gap={'md'}>
                      <Flex className={classes.testimonialImgContainer} align='center'>
                        {t.imgSrc ? (
                          <img
                            className={classes.testimonialImg}
                            src={t.imgSrc}
                            style={{ objectPosition: t.imgPos ?? 'center' }}
                          ></img>
                        ) : (
                          <IconUser className={classes.testimonialImg}></IconUser>
                        )}
                      </Flex>

                      <p className={classes.testimonialText} style={{ marginTop: 'xl' }}>
                        {t.text}
                      </p>

                      <p style={{ marginTop: '12px' }}>- {t.by}</p>
                    </Flex>
                  </MofCard>
                </Flex>
              </Grid.Col>
            ))}
          </Grid>
        </Flex>
      </Tile>
    </div>
  )
}
