import { createStyles, Flex } from '@mantine/core'

import folderIcon from '../assets/misterio/folder.png'

const useStyles = createStyles((theme) => ({
  innerFolderImg: {
    height: '70%',
    width: '92%',
    objectFit: 'cover',
    marginTop: 34,
    position: 'absolute',
    zIndex: 1,

    [theme.fn.smallerThan('xs')]: {
      marginTop: '27px'
    }
  },

  folderImg: {
    height: 180,
    zIndex: 2,

    [theme.fn.smallerThan('xs')]: {
      height: 140,
      width: 166
    }
  },

  folderWrapper: {
    height: 180,
    position: 'relative',

    [theme.fn.smallerThan('xs')]: {
      height: 140,
      width: 166
    }
  },

  textOverlay: {
    textAlign: 'center',
    position: 'absolute',
    zIndex: 3,
    marginTop: 28
  }
}))

export const FolderButton = ({
  name,
  src,
  imgText,
  imgObjectFit = 'cover',
  blur = false,
  onClick
}: {
  name: string
  src: string
  imgObjectFit?: 'contain' | 'cover'
  imgText?: string,
  blur?: boolean,
  onClick?: () => unknown
}): JSX.Element => {
  const { classes } = useStyles()
  return (
    <Flex
      className='underline-animation-wrapper'
      direction={'column'}
      justify={'center'}
      align={'center'}
      onClick={onClick}
    >
      <Flex className={classes.folderWrapper} direction={'column'} justify={'center'} align={'center'}>
        {imgText && <strong className={classes.textOverlay}>{imgText}</strong>}
        {src && (<img className={classes.innerFolderImg} src={src} style={{ objectFit: imgObjectFit, filter: blur ? 'blur(6px)' : 'none' }}></img>)}
        <img className={classes.folderImg} src={folderIcon}></img>
      </Flex>
      
      <p className='hover-underline-animation' style={{ textAlign: 'center' }}>
        {name}
      </p>
    </Flex>
  )
}
