/* eslint-disable quotes */
import { Sections } from '../components/sections'

export const TermsConditions = (): JSX.Element => {
  const sections = [
    { title: 'IMPORTANT LEGAL DISCLAIMER' },
    {
      title: 'RISKS OF PRODUCT USE',
      content: (
        <div>
          {
            'This website’s content is not a substitute for direct, personal, professional medical care and diagnosis. None of the exercise programs should be performed or otherwise used without clearance from your physician or health care provider first. The information contained within is not intended to provide specific physical or mental health advice, or any other advice whatsoever, for any individual or company and should not be relied upon in that regard. We are not medical professionals and nothing on this website should be misconstrued to mean otherwise.'
          }
          <br />
          {
            'There may be risks associated with participating in activities mentioned on our site for people in poor health or with pre-existing physical or mental health conditions. If you are in poor health or have a pre-existing mental or physical condition. If you choose to participate in these risks, you do so of your own free will and accord, knowingly and voluntarily assuming all risks associated with such dietary activities. These risks may also exist for those who are currently in good health right now.'
          }
          <br />
          {
            'As with any exercise program, you assume certain risks to your health and safety. Any form of exercise program can cause injuries, and our programs are no exception. It is possible that you may become injured doing the exercises in your program, especially if they are done with poor form. Although thorough instruction is included on the form for each exercise.'
          }
          <br />
          {
            'Be aware that our programs (like any other exercise program) do involve a risk of injury. If you choose to participate in these risks, you do so of your own free will and accord, knowingly and voluntarily assuming all risks associated with such exercise activities. These risks may also exist for those who are currently in good health right now.'
          }
          <br />
          {
            "We are not medical practitioners. Our advice whether it be on our website, in our meal plans, exercise program's or via email coaching, none of it is meant as a substitute for medical advice. You must consult your doctor before beginning ANY exercise program, with no exceptions. You are using our plans, programs, workout's and coaching at your own risk and we are not responsible for any injuries or health problems you may experience or even death as a result of using our programs."
          }
          <br />
          {
            'It is to be made clear that we are not responsible for any injuries or health problems you may experience or even death as a result of using any of our products or services.'
          }
          <br />
        </div>
      ),
    },
    {
      title: 'GENERALLY EXPECTED RESULTS',
      content: (
        <div>
          {
            'Although our products and services are intended to be fully implemented, sometimes they are not, which could result in a lack of progress/results for the user. If you implement the products and services from us correctly you should see amazing results, however, it must be disclaimed that even when consumers implement any products or services in full from us it is still possible they will not get the results they may have expected and it is also possible they will not lose fat or gain muscle or achieve any positive results of any kind.'
          }
        </div>
      ),
    },
    {
      title: 'TESTIMONIAL DISCLAIMER',
      content: (
        <div>
          {
            'All the transformations and testimonials are real. However, it must be disclaimed that these testimonials are not claimed to represent typical results with our workout programs. They are meant as a showcase of what the most motivated and dedicated people can achieve by following our workout programs. Your results may vary and you may not get the same results compared to someone else when using our services due to differences in your individual exercise history, genetics, and personal motivation/dedication. The end results you get will depend upon the individual and how much effort you put in.'
          }
        </div>
      ),
    },
    {
      title: 'CANCELLATION',
      content: (
        <div>
          {
            'If you purchase in person 1-1 training or the hybrid program then all payments are made prior to appointments and in full. 24 hours notice is needed to re-arrange any session or your cost will be retained and your session will be seen as taken.'
          }
          <br />
          {
            'If you have any questions please send us an email. You can contact the Customer Support team via the contacts page and we will be more than happy to answer any of your questions.'
          }
        </div>
      ),
    },
  ]

  return <Sections heading='Terms & Conditions' sections={sections}></Sections>
}
