/* eslint-disable camelcase */
import { FormEvent, useEffect, useState } from 'react'
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { StripePaymentElementOptions } from '@stripe/stripe-js'
import { Button, createStyles, Loader } from '@mantine/core'

const useStyles = createStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}))

export const OrderCheckoutForm = ({ displayTotal, returnUrl }: { displayTotal?: string, returnUrl: string }): JSX.Element => {
  const { classes } = useStyles()
  const stripe = useStripe()
  const elements = useElements()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_email, setEmail] = useState('')
  const [message, setMessage] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) return

    updateStripeIntentMessage()
  }, [stripe])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
    } else {
      setMessage('An unexpected error occurred.')
    }

    setIsLoading(false)
  }

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
  }

  const updateStripeIntentMessage = () => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    )

    if (!clientSecret) {
      return
    }

    stripe?.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }

  return (
    <form id='payment-form' onSubmit={handleSubmit} className={classes.form}>
      <LinkAuthenticationElement
        id='link-authentication-element'
        onChange={(e) => setEmail(e.value.email)}
      />

      <PaymentElement id='payment-element' options={paymentElementOptions} />

      <Button
        type="submit"
        disabled={isLoading || !stripe || !elements}
        variant='default'
        radius={'xl'}
        size='md'
        mt={'lg'}
      >
        {isLoading ? <Loader /> : `Purchase (£${displayTotal || ' - '})`}
      </Button>
      
      {/* Show any error or success messages */}
      {message && <div id='payment-message'>{message}</div>}
    </form>
  )
}
