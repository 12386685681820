import { showNotification } from '@mantine/notifications'

import { subscribe } from './state'

export const handleErrorPopupEffect = () => {
  subscribe('error', (error) => {
    console.log('An error has occurred', error)

    showNotification({
      title: 'Ooops! An error has occurred...',
      message: `${error}`,
      color: 'red',
      autoClose: false,
    })
  })
}

// kinda broken, as it doesn't sometimes work due to render cycle (i think)
export const handleUserLoggedInPopupEffect = (initState: { userId?: string }) => {
  let previousState = initState

  subscribe('userId', (userId) => {
    if (userId === previousState.userId) return

    previousState = { userId }

    showNotification({
      title: 'Welcome',
      message: 'You are logged in',
      color: 'cyan',
      autoClose: 5000,
    })
  })
}
