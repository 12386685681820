import {
  ActionIcon,
  Burger,
  Container,
  createStyles,
  Group,
  Header,
  Paper,
  Transition,
} from '@mantine/core'
import { useClickOutside, useDisclosure } from '@mantine/hooks'
import { IconBrandFacebook, IconBrandInstagram } from '@tabler/icons'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGlobalState } from '../state/state'
import { Logo } from './logo'
import { ToolbarAuth } from './toolbar-auth'

const HEADER_HEIGHT = 56

const useStyles = createStyles((theme) => ({
  header: {
    position: 'fixed',
    top: 0,
    backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  headerImage: {
    position: 'fixed',
    top: 0,
    backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: HEADER_HEIGHT,

    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'flex-start',
    },
  },

  links: {
    flex: 1,

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  homeLink: {
    display: 'flex',
    justifyContent: 'center',
  },

  social: {
    flex: 1,

    [theme.fn.smallerThan('sm')]: {
      width: 'auto',
      marginLeft: 'auto',
    },
  },

  burger: {
    marginRight: theme.spacing.md,

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },
}))

interface HeaderMiddleProps {
  links: { link: string; label: string }[]
  home?: { link: string }
  backgroundImage?: string
}

export const Toolbar = ({ links, home, backgroundImage }: HeaderMiddleProps): JSX.Element => {
  const [config] = useGlobalState('config')
  const [opened, { toggle }] = useDisclosure(false)
  const [active, setActive] = useState('')
  const { classes, cx } = useStyles()
  const ref = useClickOutside(() => {
    if (opened) toggle()
  })

  const items = links.map((link) => (
    <Link
      key={link.label}
      to={link.link}
      className={cx(classes.link, { [classes.linkActive]: active === link.link })}
      onClick={() => {
        setActive(link.link)
        toggle()
      }}
    >
      {link.label}
    </Link>
  ))

  const homeClicked = () => setActive(home?.link ?? '')

  return (
    <Header
      className={backgroundImage ? classes.headerImage : classes.header}
      height={56}
      style={{ backgroundImage: backgroundImage }}
    >
      <Container className={classes.inner}>
        <Burger opened={opened} onClick={toggle} size='sm' className={classes.burger} />

        <Transition transition='pop-top-right' duration={200} mounted={opened}>
          {(styles) => (
            <Paper ref={ref} className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>

        <Group className={classes.links} spacing={5}>
          {items}
        </Group>

        <Link className={classes.homeLink} to={home?.link ?? '/'} onClick={homeClicked}>
          <Logo size={52} />
        </Link>

        <Group spacing={8} className={classes.social} position='right' noWrap>
          <ToolbarAuth></ToolbarAuth>

          <ActionIcon size='lg' component='a' href={config?.social.instagram}>
            <IconBrandInstagram size={18} stroke={1.5} />
          </ActionIcon>

          <ActionIcon size='lg' component='a' href={config?.social.facebook}>
            <IconBrandFacebook size={18} stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
    </Header>
  )
}
