import { MisterioProjectProduct } from './misterio-project-product'

export function getCheckoutProductDetails(productId: string): JSX.Element | undefined {
  const product = getProduct(productId)

  return product?.checkoutDetails()
}

function getProduct(productId: string) {
  switch (productId) {
    case MisterioProjectProduct.id:
      return MisterioProjectProduct

    default:
      break
  }
}
