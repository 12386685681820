import { createStyles, Flex, Grid, Modal } from '@mantine/core'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import misterioLogo from '../../assets/misterio_logo.png'
import { AngularStyle } from '../../components/angular-style'
import { Video, VideoPlayer } from '../../components/video-player'
import { hasUserProduct } from '../../helpers/user.helper'
import { MISTERIO_PRODUCT_ID } from '../../products/misterio-project-product'

import { DocumentButton } from '../../shared/document-button'
import { FolderButton } from '../../shared/folder-button'
import { useGlobalState } from '../../state/state'
import { MisterioStageData } from './misterio-stage.constants'
import { getStage } from './misterio-stage.helper'

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing.sm,
    margin: '0 350px',
    marginBottom: `${theme.spacing.xl}px !important`,

    [theme.fn.largerThan(theme.breakpoints.xl * 1.5)]: {
      margin: '0 600px',
    },

    [theme.fn.smallerThan('xl')]: {
      margin: '0 180px',

      [theme.fn.smallerThan('lg')]: {
        margin: '0 140px',

        [theme.fn.smallerThan('md')]: {
          margin: '0 60px',

          [theme.fn.smallerThan('sm')]: {
            margin: '0 16px',
          },
        },
      },
    },
  },

  title: {
    margin: '0 !important',
  },

  subTitle: {
    margin: 0,
  },

  misterioImg: {
    objectFit: 'cover',
    // width: '100%',
    height: '180px',
  },

  videoModal: {
    '.mantine-Modal-modal': {
      height: '100% !important',

      [theme.fn.smallerThan('md')]: {
        width: '100% !important',
      },
    },

    '.mantine-Modal-body': {
      height: 'calc(100% - 50px) !important',
    },
  },

  backButton: {
    padding: '4px 16px',
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.colors['brand'][9],
    fontSize: theme.fontSizes.md,
  },
}))

export const MisterioStage = (): JSX.Element => {
  const [loadingUserData] = useGlobalState('loadingUserData')
  const hasMisterio = hasUserProduct(MISTERIO_PRODUCT_ID)
  const navigate = useNavigate()

  const { classes } = useStyles()

  const [error, setError] = useState<string | null>(null)
  const [stage, setStage] = useState<MisterioStageData | null>(null)

  const [modalTitle, setModalTitle] = useState<string | null>(null)
  const [modalContent, setModalContent] = useState(<></>)
  const [modalOpened, setModalOpened] = useState(false)

  const openVideoPlayerModal = (video: Video) => {
    setModalTitle(video.name)
    setModalContent(<VideoPlayer video={video}></VideoPlayer>)
    setModalOpened(true)
  }

  const { stage: stageNumStr } = useParams()

  useEffect(() => {
    if (!loadingUserData && !hasMisterio) {
      navigate('/') // redirect to home
    }

    const stageNum = Number(stageNumStr)
    const _stage = getStage(stageNum)
    setStage(_stage)
    if (!_stage) {
      setError(`Stage ${stageNumStr} not found`)
    } else {
      setError(null)
    }
  }, [loadingUserData, hasMisterio, stageNumStr])

  const documents = stage && stage.docs.map((file) => <DocumentButton key={file.name} {...file} />)

  const vids =
    stage &&
    stage?.videos.map((video, i) => (
      <Grid.Col key={i} span={4}>
        <FolderButton
          {...video}
          src={video.thumbnail || ''}
          onClick={() => openVideoPlayerModal(video)}
        />
        {/* </Link> */}
      </Grid.Col>
    ))

  return (
    <>
      <div style={{ position: 'relative' }}>
        <AngularStyle
          colour='brand-secondary'
          style={3}
          side={'left'}
          size={'sm'}
          height={'50px'}
          width={'100%'}
        ></AngularStyle>

        <Flex className={classes.backButton} h={'100%'} align={'center'}>
          <Link
            className='underline-animation-wrapper'
            style={{ cursor: 'pointer' }}
            to={'/misterio'}
          >
            <span className='hover-underline-animation light'>Back</span>
          </Link>
        </Flex>
      </div>

      <div className={classes.wrapper}>
        <Link style={{ cursor: 'pointer' }} to={'/misterio'}>
          <img className={classes.misterioImg} src={misterioLogo}></img>
        </Link>

        {error && <p>{error}</p>}

        {stage && (
          <Flex justify={'center'} align={'center'} direction={'column'} gap={'md'}>
            <h3 className={classes.title}>{stage.title}</h3>
            {stage.subTitle && <h4 className={classes.subTitle}>{stage.subTitle}</h4>}

            <Flex gap={48}>{documents}</Flex>

            <Grid h={'100%'} grow gutter={'md'} justify='center' align={'stretch'}>
              {vids}
            </Grid>
          </Flex>
        )}
      </div>

      <Modal
        className={classes.videoModal}
        size={'75%'}
        padding={'md'}
        title={modalTitle}
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
      >
        {modalContent}
      </Modal>
    </>
  )
}
