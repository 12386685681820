import { createStyles, List, ThemeIcon } from '@mantine/core'
import { IconCheck } from '@tabler/icons'

export const MISTERIO_PRODUCT_ID = 'ec3b72bb-8cb9-4b29-87a7-e81a50c7cab2'
export const MISTERIO_PRODUCT_TITLE = 'Misterio Programme'
export const MISTERIO_PRODUCT_SHORT_DESC =
  'A functional training programme designed to get you in the best shape of your life.'

const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,

    [theme.fn.smallerThan('lg')]: { padding: theme.spacing.lg },
    [theme.fn.smallerThan('sm')]: { padding: theme.spacing.md },

    h2: {
      margin: 0,
    },
  },

  listedItems: {
    marginBottom: 0,
  },

  footer: {
    padding: `${theme.spacing.md}px 0`,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  discountButton: {
    alignSelf: 'start',
    margin: `0 ${theme.spacing.xs}px`,
  },

  price: {
    padding: `0 ${theme.spacing.xl}px`,
    fontSize: theme.fontSizes.xl,
  },
}))

export const MisterioProjectProduct = {
  id: MISTERIO_PRODUCT_ID,
  title: MISTERIO_PRODUCT_TITLE,
  shortDesc: MISTERIO_PRODUCT_SHORT_DESC,
  checkoutDetails: () => {
    const { classes } = useStyles()

    return (
      <div className={classes.wrapper}>
        <h2>{MISTERIO_PRODUCT_TITLE}</h2>
        <p>{MISTERIO_PRODUCT_SHORT_DESC}</p>

        <List
          mt={30}
          spacing='sm'
          size='sm'
          icon={
            <ThemeIcon size={20} radius='xl'>
              <IconCheck size={12} stroke={1.5} />
            </ThemeIcon>
          }
        >
          <List.Item>A Training manual with the structure of the programme, and more</List.Item>

          <List.Item>Access to my private youtube channel with all of the exercises</List.Item>

          <List.Item>Nutrtional guidance</List.Item>

          <List.Item>Cardio workouts</List.Item>

          <List.Item>A Training Log to record all your workouts</List.Item>

          <List.Item>One-time purchase</List.Item>
        </List>
      </div>
    )
  },
}
