import {
  createStyles,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
  Flex,
  Loader,
} from '@mantine/core'
import { IconCheck } from '@tabler/icons'
import { Link } from 'react-router-dom'

import { Tile, TileOpts } from '../../components/tile'
import misterioLogo from '../../assets/misterio_smoky_logo.png'
import { MISTERIO_PRODUCT_ID } from '../../products/misterio-project-product'
import { hasUserProduct } from '../../helpers/user.helper'
import { useGlobalState } from '../../state/state'
import { embeddedSrc } from '../../helpers/youtube.helper'

const useStyles = createStyles((theme) => ({
  inner: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    [theme.fn.smallerThan('sm')]: {
      paddingTop: theme.spacing.md,
      paddingBottom: theme.spacing.md,
    },
  },

  content: {
    maxWidth: '78%',
    padding: theme.spacing.xl,
    paddingRight: '0',

    [theme.fn.smallerThan('md')]: {
      padding: theme.spacing.lg,

      [theme.fn.smallerThan('sm')]: {
        maxWidth: '100%',
        padding: theme.spacing.md,
        paddingTop: 0,
      },
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 44,
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan('xs')]: {
      fontSize: '36px !important',
    },
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,
    backgroundColor: theme.white,

    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  priceSubText: {
    fontSize: theme.fontSizes.xs,
  },

  misterioImg: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },

  avatarWrapper: {
    flexShrink: 0,
    width: '200px',
    height: '200px',

    [theme.fn.smallerThan('md')]: {
      width: '80px',
      height: '80px',
    },
  },

  avatarContent: {
    width: '100%',
    height: '100%',
    background: 'black',
    borderRadius: '150px',
    borderStyle: 'solid',
    borderWidth: '4px',
    overflow: 'overlay',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.fn.smallerThan('md')]: {
      borderWidth: '2px',
    },
  },

  titleText: {
    wordWrap: 'break-word',
    [theme.fn.smallerThan('md')]: {
      fontSize: `${theme.fontSizes.xl}px !important`,
    },
  },

  promoVidWrapperMobile: {
    right: 0,
    top: 0,
    width: '80%',
    position: 'relative',
    margin: 'auto',
    marginTop: '12px',
    zIndex: 0,
    display: 'flex',
    alignItems: 'center',

    '::after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },

    [theme.fn.smallerThan('xs')]: {
      width: '100%',
    },
  },

  promoVidModile: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  promoVidWrapperDesktop: {
    [theme.fn.largerThan('sm')]: {
      position: 'absolute',
      right: 0,
      top: 0,
      width: '350px',
      height: '100%',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      [theme.fn.smallerThan('sm')]: {
        position: 'relative',
        width: '100%',
        margin: `${theme.spacing.md}px 0`,
        zIndex: 0,
      },
    },

    [theme.fn.smallerThan('sm')]: {
      dispay: 'none',
    },
  },

  promoVidDesktop: {
    backgroundColor: '#1b1e1e',
    padding: 12,
    margin: 12,
    height: '350px',
    width: '350px',
    borderRadius: '4px',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
}))

export function MisterioPackageDetails() {
  const { classes } = useStyles()

  const hasMisterio = hasUserProduct(MISTERIO_PRODUCT_ID)
  const [loadingUserData] = useGlobalState('loadingUserData')
  const [config] = useGlobalState('config')

  const tile: TileOpts = {
    style: 'sm-media-1',
    colour: 'bg-secondary',
    accent: 'brand-secondary',
    radius: 12,
  }

  return (
    <Container h={'100%'}>
      <div className={classes.inner}>
        <Tile tileOpts={tile} r={14}>
          <div className={classes.promoVidWrapperDesktop}>
            <iframe
              className={classes.promoVidDesktop}
              src={embeddedSrc('lifUWXFS9BU')}
              allow='autoplay'
              frameBorder='0'
              allowFullScreen
            ></iframe>
          </div>

          <div className={classes.content}>
            <Title className={classes.title}>
              <Flex direction={'row'} align={'center'} gap={'md'}>
                <div className={classes.avatarWrapper}>
                  <div className={classes.avatarContent}>
                    <img className={classes.misterioImg} src={misterioLogo}></img>
                  </div>
                </div>

                <Flex direction={'column'} justify={'space-around'} align={'start'} wrap={'wrap'}>
                  <div className={classes.titleText}>
                    <span>Misterio Programme</span>
                  </div>
                </Flex>
              </Flex>
            </Title>

            <div className={classes.promoVidWrapperMobile}>
              <iframe className={classes.promoVidModile}
                src={embeddedSrc('lifUWXFS9BU')}
                allow='autoplay'
                frameBorder='0'
                allowFullScreen
              ></iframe>
            </div>

            <Text color='dimmed' mt='md'>
              Misterio is a functional training programme designed to get you in the best shape of
              your life. The programme will include the following:
            </Text>

            <List
              mt={30}
              spacing='sm'
              size='sm'
              icon={
                <ThemeIcon size={20} radius='xl'>
                  <IconCheck size={12} stroke={1.5} />
                </ThemeIcon>
              }
            >
              <List.Item>A Training manual with the structure of the programme, and more</List.Item>

              <List.Item>Access to my private youtube channel with all of the exercises</List.Item>

              <List.Item>Nutrtional guidance</List.Item>

              <List.Item>Cardio workouts</List.Item>

              <List.Item>A Training Log to record all your workouts</List.Item>
            </List>

            <Group mt={30} style={{ gap: '8px' }}>
              {hasMisterio ? (
                <p>🎊 Purchased 🎊</p>
              ) : (
                <Flex direction={'column'} lh={'normal'}>
                  <b style={{ margin: 0 }}>£{config?.misterioProductPrice}</b>
                  <p className={classes.priceSubText}>One-time purchase</p>
                </Flex>
              )}

              {hasMisterio ? (
                <Link to={'/misterio'}>
                  <Button variant='default' radius='xl' size='md' className={classes.control}>
                    Go to Misterio
                  </Button>
                </Link>
              ) : (
                <Link
                  to={loadingUserData ? '' : `/order-checkout?productId=${MISTERIO_PRODUCT_ID}`}
                  style={{ margin: '1.2em' }}
                >
                  <Button
                    disabled={loadingUserData}
                    variant='default'
                    radius='xl'
                    size='md'
                    className={classes.control}
                  >
                    {loadingUserData ? <Loader /> : 'Purchase'}
                  </Button>
                </Link>
              )}
            </Group>
          </div>
        </Tile>
      </div>
    </Container>
  )
}
