import { createStyles } from '@mantine/core'
import { Key, ReactNode } from 'react'
import { CardsCarousel, CarouselCardProps } from './carousel'

export const splashClassProps: Record<Key, string> = {
  height: '750px',
  backgroundRepeat: 'no-repeat !important',
  backgroundPositionY: 'center !important',
  backgroundPositionX: 'center !important',
  backgroundSize: 'cover !important',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width:'100%'
}

const useStyles = createStyles(() => ({
  splash: splashClassProps,
  carouselChildren: {
    position: 'absolute',
    top: '0',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    pointerEvents: 'none',
    height: '100%',
    width: '100%'
  },
}))

export interface SplashOpts {
  children: ReactNode
  backgroundSrc?: string
  carouselImages?: CarouselCardProps[]
  height?: number | string
  className?: string
}

export const Splash = (props: SplashOpts): JSX.Element => {
  const { classes } = useStyles()
  const splashClass = props.className ?? classes.splash

  if (Array.isArray(props.carouselImages) && props.carouselImages.length) {
    return (
      <div className={splashClass} style={{ height: props.height }}>
        <CardsCarousel slides={props.carouselImages} ></CardsCarousel>

        <div className={classes.carouselChildren}>{props.children}</div>
      </div>
    )
  }

  const backgroundSrc = props.backgroundSrc

  return (
    <div
      className={splashClass}
      style={{
        background: backgroundSrc,
        height: props.height,
      }}
    >
      {props.children}
    </div>
  )
}

export const SplashTitle = (props: { children: ReactNode }): JSX.Element => {
  const { classes } = createStyles((theme) => ({
    splashTitle: {
      margin: theme.spacing.xl,

      [theme.fn.smallerThan('sm')]: {
        fontSize: `${theme.fontSizes.lg * 2}px !important`
      }
    },
  }))()

  return (
    <div className='splash-title'>
      <div className={classes.splashTitle}>{props.children}</div>
    </div>
  )
}

export const SplashContent = (props: { children: ReactNode }): JSX.Element => (
  <div className='splash-content'>{props.children}</div>
)
