import { ReactNode } from 'react'
import { TileContent } from './tile-content'
import { TileHeader } from './tile-header'

import { TileStyle } from './tile-styles'

export type TileAvatarSize = 'md' | 'lg' | 'xl'
export type TileColours =
  | 'bg-primary'
  | 'bg-secondary'
  | 'bg-tertiary'
  | 'bg-black'
  | 'brand-primary'
  | 'brand-secondary'
  | 'brand-tertiary'
export type TileStyleType =
  | 'simple-1'
  | 'simple-2'
  | 'simple-3'
  | 'sm-media-1'
  | 'md-media-1'
  | 'lg-media-1'
  | 'lg-media-2'

export interface TileOpts {
  style?: TileStyleType
  colour?: TileColours
  accent?: TileColours
  media?: string
  avatar?: string // if media if an avatar
  avatarSize?: TileAvatarSize
  header?: string
  greyscaleMedia?: boolean
  radius?: number
}

export const Tile = (props: {
  tileOpts: TileOpts
  children: ReactNode
  mh?: number | string
  h?: number | string
  r?: number | string
}): JSX.Element => {
  const opts = props.tileOpts
  const mh = props.mh
  const h = props.h
  const r = props.r

  return (
    <div className={`tile ${opts.colour ?? 'bg-primary'}`} style={{ minHeight: mh, height: h, borderRadius: r ?? 0, overflow: r ? 'hidden' : 'visible' }}>
      <TileStyle
        {...opts}
        style={opts.style ?? 'simple-1'}
        colour={opts.accent ?? 'bg-tertiary'}
        altColour={opts.colour}
      >
        <TileContent tileOpts={opts}>{props.children}</TileContent>
      </TileStyle>
      <TileHeader>{opts.header}</TileHeader>
      {/* content to be passed in as children of tile */}
    </div>
  )
}
