import { MantineThemeOverride } from '@mantine/core'

export const Theme: MantineThemeOverride = {
  colorScheme: 'dark',
  colors: {
    brand: [
      '#FF5662',
      '#FF1616',
      '#FF1616',
      '#FF1616',
      '#DB0000',
      '#B70000',
      '#740000',
      '#727272',
      '#494949',
      '#121414',
    ],
    dark: [
      '#E6E6E6',
      '#E6E6E6',
      '#CECECE',
      '#9F9F9F',
      '#727272',
      '#727272',
      '#494949',
      '#494949',
      '#232323',
      '#121414',
    ],
  },
  primaryColor: 'dark',
  fontFamily: 'Squada One, cursive',
  fontSizes: {
    xs: 14,
    sm: 16,
    md: 18,
    lg: 20,
    xl: 26,
  },
  spacing: {
    lg: 24,
    xl: 48,
  },
  fontFamilyMonospace: 'Squada One, cursive',
  headings: {
    fontFamily: 'Racing Sans One cursive',
    fontWeight: 400,
    sizes: {
      // Todo: Fix this, config here is not working!
      h2: { fontSize: 1000 },
      // h1: { fontSize: 80, fontWeight: 700, lineHeight: 1.2 },
    },
  },
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400,
  },
  components: {
    Popover: {
      styles: (theme) => ({
        dropdown: {
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          minWidth: '250px',
          
          [theme.fn.smallerThan('xs')]: {
            minWidth: '100%'
          }
        },
      })
    },
  },
}
