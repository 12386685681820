import { AccountInfo, EventType, PublicClientApplication } from '@azure/msal-browser'

import { loginRequest, msalConfig } from '../auth-config'
import { logoutUser, loginUser } from './user.helper'

let msalInstance: PublicClientApplication | undefined = undefined

export const initMsalClient = () => {
  /**
   * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
   */
  msalInstance = new PublicClientApplication(msalConfig)

  // Default to using the first account if no account is active on page load
  if (msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    setActiveAccount(msalInstance, msalInstance.getAllAccounts()[0])
  }

  msalInstance.addEventCallback((event) => {
    if (
      msalInstance &&
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      (event.payload as AccountInfo)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const account = (event?.payload as any)?.account || event.payload
      setActiveAccount(msalInstance, account as AccountInfo)
    }
  })

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGOUT_SUCCESS) {
      logoutUser()
    }
  })

  console.log('have instance')

  return msalInstance
}

const setActiveAccount = (msalInstance: PublicClientApplication, account: AccountInfo) => {
  if (account) {
    console.log('setActiveAccount', account)
    msalInstance.setActiveAccount(account)

    loginUser(account.localAccountId).then(() => {
      console.debug('Login data loaded')
    })
  }
}

export async function getAccessToken(): Promise<string> {
  if (!msalInstance) {
    throw new Error('Auth instance must be setup')
  }
  
  // const account = useAccount() || undefined
  const account = msalInstance.getActiveAccount() || undefined

  const { accessToken } = await msalInstance.acquireTokenSilent({ account, scopes: loginRequest.scopes })

  if (!accessToken) {
    try {
      throw new Error('Failed to get access token!')
    } catch (e) {
      console.log(e)
      msalInstance.loginRedirect() // Untested path
      return ''
    }
  }

  return accessToken
}
