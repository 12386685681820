import { createStyles, Flex, Footer } from '@mantine/core'
import { Link } from 'react-router-dom'

const useStyles = createStyles((theme) => ({
  footer: {
    height: '28px',
    [theme.fn.smallerThan('sm')]: {
      height: '18px',
    },
  },

  inner: {
    margin: '0 18px',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '10px',
    [theme.fn.largerThan('lg')]: {
      fontSize: '12px',
    },
  },

  links: {
    a: {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
}))

export const MofFooter = (): JSX.Element => {
  const { classes } = useStyles()
  return (
    <Footer className={classes.footer} height={28}>
      <div className={classes.inner}>
        {/* <Group className={classes.links}>{items}</Group> */}
        <Flex w={'100%'} h={'100%'} align={'center'} justify={'space-between'} gap={'md'}>
          <Flex className={classes.links} justify={'flex-start'} gap={'md'}>
            <Link to={'privacy-policy'}>Privacy Policy</Link>
            <Link to={'terms-conditions'}>Terms & Conditions</Link>
          </Flex>

          <Flex justify={'flex-end'}>
            <p>Mistry of Fitness</p>
          </Flex>
        </Flex>
      </div>
    </Footer>
  )
}
