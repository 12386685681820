import { createStyles, Flex } from '@mantine/core'

export const Sections = ({
  sections,
  heading,
}: {
  sections: { title?: string; content?: JSX.Element }[]
  heading: string
}): JSX.Element => {
  const { classes } = createStyles((theme) => ({
    wrapper: {
      margin: 'auto',
      width: '50%',
      [theme.fn.smallerThan('md')]: {
        width: '75%',
        [theme.fn.smallerThan('sm')]: {
          width: '90%',
        },
      },
    },
    title: {
      color: theme.colors.brand[1],
    },
    content: {},
  }))()

  return (
    <Flex className={classes.wrapper} direction={'column'} gap={'lg'}>
      <h1 style={{ marginTop: 0 }}>{heading}</h1>

      {sections.map((s) => (
        <Flex key={s.title} direction={'column'}>
          <div className={classes.title}>{s.title}</div>
          <div className={classes.content}>{s.content}</div>
        </Flex>
      ))}
    </Flex>
  )
}
