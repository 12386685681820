import { Center, Flex } from '@mantine/core'
import { Avatar } from './avatar'
import { TileAvatarSize, TileColours } from './tile'

interface AngularStyleOpts {
  className?: string // Used for mantine MediaQuery
  style: 1 | 2 | 3 | 4 | 5
  colour: TileColours // Todo: Change TileColour to something more generic
  altColour?: TileColours // Todo: Change TileColour to something more generic
  side: 'right' | 'left' | 'top' | 'bottom'
  size: 'sm' | 'md' | 'lg'
  avatar?: string
  avatarSize?: TileAvatarSize
  media?: string
  altOverlayMedia?: boolean
  greyscaleMedia?: boolean
  height?: number | string
  width?: number | string
}
export const AngularStyle = ({
  className,
  style,
  colour,
  altColour,
  side,
  size,
  avatar,
  avatarSize,
  media,
  altOverlayMedia,
  greyscaleMedia,
  height,
  width
}: AngularStyleOpts) => {
  if (avatar && altColour) {
    return (
      <Center className={className}>
        <Flex
          className={`angular-style-wrapper ${side}-${style}-${size}`}
          justify='start'
          align='center'
          h={'100%'}
        >
          <div className={`angular-shape ${side}-${style}-${size} ${colour}`}></div>
          <div className='angular-avatar-wrapper'>
            <Avatar
              img={avatar}
              size={avatarSize ?? 'md'}
              colour={altColour}
              greyscale={greyscaleMedia ?? false}
            ></Avatar>
          </div>
        </Flex>
      </Center>
    )
  }

  if (media) {
    if (altOverlayMedia && altColour) {
      return (
        <Flex direction={'column'}>
          <Flex
            className={`angular-style-wrapper ${side}-${style}-${size}`}
            pos={'relative'}
            align={'center'}
            w={560}
            h={670}
          >
            <div
              className={`angular-shape ${side}-${style}-${size} ${altColour}`}
              style={{ opacity: 1, zIndex: 1000, bottom: '-1px' }}
            ></div>
            <div
              className={`angular-shape ${side}-${style}-${size} ${colour}`}
              style={{ zIndex: 1000, bottom: '-1px' }}
            ></div>

            <div className='angular-media-wrapper'>
              <img src={media} width={'100%'} />
            </div>
          </Flex>

          <div style={{ flex: 1, backgroundColor: 'black' }}></div>
        </Flex>
      )
    }

    return (
      <div
        className={`angular-style-wrapper ${side}-${style}-${size}`}
        style={{ alignItems: 'flex-end' }}
      >
        <div className={`angular-shape ${side}-${style}-${size} ${colour}`}></div>
        <div className='angular-media-wrapper'>
          <img src={media} width={520} />
        </div>
      </div>
    )
  }

  return (
    <Flex className={className} h={height} w={width}>
      <div className={`angular-style-wrapper ${side}-${style}-${size}`} style={{height, width}}>
        <div className={`angular-shape ${side}-${style}-${size} ${colour}`}  style={{height, width}}></div>
      </div>
    </Flex>
  )
}
