import { createGlobalState } from 'react-hooks-global-state'

import { handleErrorPopupEffect, handleUserLoggedInPopupEffect } from './state.effects'
import { Config } from '../config'

const initState: {
  userId?: string
  userProductIds: string[]
  loadingUserData: boolean
  error?: unknown
  config?: Config
} = {
  userId: undefined,
  userProductIds: [],
  loadingUserData: false,
  error: undefined,
  config: undefined
}
const { setGlobalState, useGlobalState, subscribe, getGlobalState } = createGlobalState(initState)

handleErrorPopupEffect()
handleUserLoggedInPopupEffect({ userId: initState.userId })

export const setUserProductIds = (ids: string[]) => {
  setGlobalState('userProductIds', ids)
}

export const setUserId = (id?: string) => {
  setGlobalState('userId', id)
}

export const setLoadingUserData = (loading: boolean) => {
  setGlobalState('loadingUserData', loading)
}

export const setError = (error: unknown) => {
  setGlobalState('error', error)
}

export const setConfig = (config: Config) => {
  setGlobalState('config', config)
}

export { useGlobalState, subscribe, getGlobalState }
