import { Flex, createStyles } from '@mantine/core'
import { ReactNode } from 'react'

const useStyles = createStyles((theme) => ({
  cardWrapper: {
    backgroundColor: theme.colors.dark['8'],
    height: '100%',
    padding: theme.spacing.lg,
    border: `1px solid ${theme.colors.brand['1']}`,
    borderRadius: theme.radius.lg,
    [theme.fn.smallerThan('sm')]: {
      padding: theme.spacing.sm,
      borderRadius: theme.radius.md,
    },
  },
}))

export const MofCard = (props: { children: ReactNode }): JSX.Element => {
  const { classes } = useStyles()

  return (
    <>
      <Flex className={classes.cardWrapper} direction={'column'} style={{ flex: 1 }} align='center'>
        {props.children}
      </Flex>
    </>
  )
}
