import { Video } from '../../components/video-player'
import { embeddedSrc, thumbnail } from '../../helpers/youtube.helper'
import { workoutDocument } from './misterio.helper'

const getYoutubeVideo = (name: string, videoId: string): Video => ({
  name,
  src: embeddedSrc(videoId),
  thumbnail: videoId ? thumbnail(videoId, 'mq') : '',
})

export type MisterioStageData = {
  title: string | JSX.Element
  subTitle?: string | JSX.Element
  docs: { name: string; src: string }[]
  videos: Video[]
}

export const Stage1: MisterioStageData = {
  title: 'Stage 1',
  docs: [workoutDocument(1)],
  videos: [
    getYoutubeVideo('Arm Circles', 'j1se74FaCXo'),
    getYoutubeVideo('Wall Angles', 'DgGOpPcph9E'),
    getYoutubeVideo('Push-Up Position IT Stretch', 'q0R098ZzNfY'),
    getYoutubeVideo('Lunge Shoulder Stretch', 'Z369L5AaXo4'),
    getYoutubeVideo('Lateral Adductor Stretch', 'AElCEPOq3zg'),
    getYoutubeVideo('Incline Push Ups', '_Fdq4-kBk7E'),
    getYoutubeVideo('Push Up', 'PJjNd8rGzio'),
    getYoutubeVideo('Scapular Retractions', 'Ar0wG195f-k'),
    getYoutubeVideo('Split Squats', 'SjLCGfTTIQE'),
    getYoutubeVideo('Bodyweight Mid Rows', 'T6L4v-bbcAA'),
    getYoutubeVideo('Bodyweight Squats', 'HfljNdqxVT0'),
    getYoutubeVideo('Plank', 'SOXrN9ChY-A'),
    getYoutubeVideo('Glute Bridge', 'X8M6orlbO0Y'),
    getYoutubeVideo('Swiss Ball Rollouts', 'vOgtxmQY8G0'),
    getYoutubeVideo('Quadriped', 'Hvm0goEyU5I'),
    getYoutubeVideo('Leg Raise', 'vc0wN6Dw-Rs'),
    getYoutubeVideo('Bodyweight Dips', 'woUTL_ZPjT0'),
    getYoutubeVideo('Bodyweight Curls On A Bar', 'jft1wCBCHtc'),
    getYoutubeVideo('Side Plank', 'OprW7RP2BHw'),
    getYoutubeVideo('Swiss Ball Knee Hold', 'ErvMCkaDJiU'),
    getYoutubeVideo('Knee Ins', 'Uvy4WAMZRJY'),
  ],
}

export const Stage2: MisterioStageData = {
  title: 'Stage 2',
  docs: [workoutDocument(2)],
  videos: [
    getYoutubeVideo('Arm Circles', 'j1se74FaCXo'),
    getYoutubeVideo('Wall Angles', 'DgGOpPcph9E'),
    getYoutubeVideo('Thoracic Rotation With Weight', '5U3rOOyg1ak'),
    getYoutubeVideo('Prone Position Lumbar Rotaion', 'FoLUG5Jsbto'),
    getYoutubeVideo('Inchworm', 'UTpyOQQYIsw'),
    getYoutubeVideo('Push-Up Position Hip Flexor Stretch', 'vAqmFOFr23A'),
    getYoutubeVideo('Push-Up Position IT Stretch', 'q0R098ZzNfY'),
    getYoutubeVideo('Lateral Adductor Stretch', 'AElCEPOq3zg'),
    getYoutubeVideo('Scorpion Push Up', '_Dq5wnppn6M'),
    getYoutubeVideo('Pull Up', 'HOSvUz9hivc'),
    getYoutubeVideo('One Arm Plank', '1-8yurd5IPo'),
    getYoutubeVideo('Wall Barrows', 'gD2jp0Yx3lo'),
    getYoutubeVideo('Hollow Body Hold', 'JlJ7U-2WE64'),
    getYoutubeVideo('Straight Arm Side Plank', '23SalNrRMtk'),
    getYoutubeVideo('Bodyweight Dips With Feet Elevated', 'zL2RPGGcSps'),
    getYoutubeVideo('Archer Mid Rows', 'l104DDMJxIU'),
    getYoutubeVideo('90 Degree Scapular Retraction', 'fYVTzjE3vC8'),
    getYoutubeVideo('Leg Raise With A  Pumped Up Kick', 'wxfRxFPfW-8'),
    getYoutubeVideo('Clockwork Squats', 'oU8GOspO92Y'),
    getYoutubeVideo('Lumbar Extension On A Swiss Ball', 'kHf4aVcmxAA'),
    getYoutubeVideo('Medicine Ball Squat Press', 'TYAq5GXewkM'),
    getYoutubeVideo('Broad Jumps', '_C8vQ7R7V3M'),
    getYoutubeVideo('Swiss Ball Knee Hold', 'ErvMCkaDJiU'),
    getYoutubeVideo('Turkish Get Ups', 'CmtjwA5v6vs'),
    getYoutubeVideo('One Legged Glute Bridge', 'UL8WzacX9S4'),
    getYoutubeVideo('Quadriped Repping', '8hMiQk950n0'),
    getYoutubeVideo('Spyder Walks', 'nm8ehV_ZXmM'),
    getYoutubeVideo('Bodyweight Curls On A Bar', 'jft1wCBCHtc'),
    getYoutubeVideo('Mid Row Grip Strength', 'WYh-Kx4vk4g'),
    getYoutubeVideo('Lateral Lunge', '5qrx1X-l5gE'),
    getYoutubeVideo('Prone Knee Ins On A Swiss Ball', 'PP31eGFF4Hg'),
  ],
}

export const Stage3: MisterioStageData = {
  title: 'Stage 3',
  docs: [workoutDocument(3, { type: 'xlsx' })],
  videos: [
    getYoutubeVideo('Arm Circles', 'j1se74FaCXo'),
    getYoutubeVideo('Wall Angles', 'DgGOpPcph9E'),
    getYoutubeVideo('Thoracic Rotation With Weight', '5U3rOOyg1ak'),
    getYoutubeVideo('Prone Position Lumbar Rotaion', 'FoLUG5Jsbto'),
    getYoutubeVideo('Lunge Shoulder Stretch', 'Z369L5AaXo4'),
    getYoutubeVideo('Push Up', 'PJjNd8rGzio'),
    getYoutubeVideo('Hanging Knee Raises', '5cjUzvrqmIY'),
    getYoutubeVideo('Hanging Scapular Shrugs', 'cpwgHnE7wjs'),
    getYoutubeVideo('Hanging Torso Twists', 'xzxNlysp-3c'),
    getYoutubeVideo('Dead Hang', '8Gb0BQbT6mY'),
    getYoutubeVideo('Inchworm', 'UTpyOQQYIsw'),
    getYoutubeVideo('Supine position Lumbar Rotation', 'oI8YlcWI2k4'),
    getYoutubeVideo('Push-Up Position Hip Flexor Stretch', 'vAqmFOFr23A'),
    getYoutubeVideo('Push-Up Position IT Stretch', 'q0R098ZzNfY'),
    getYoutubeVideo('Bodyweight Squats', 'HfljNdqxVT0'),
    getYoutubeVideo('One Arm Push Ups', 'K9kdIJViJEI'),
    getYoutubeVideo('Wall Assisted Handstand Push Up', 'C3q4ZVlWk_o'),
    getYoutubeVideo('Hollow Body Hold', 'JlJ7U-2WE64'),
    getYoutubeVideo('One Legged Spyder Walks', 'zBhI_fMqTFA'),
    getYoutubeVideo('Full Body Rises', 'wh1TnIFpgo8'),
    getYoutubeVideo('Push Up To Dip', 'nBy-scQo9oI'),
    getYoutubeVideo('Straight Leg Lumbar Rotation With A Swiss Ball', 'a01DR2VPMDY'),
    getYoutubeVideo('Renegade Rows With A Walkthrough', 'vHM828bmdCw'),
    getYoutubeVideo('Around The World', 'Kl6eMN6zmok'),
    getYoutubeVideo('Band Assisted 1 Arm Pull Up', 'uoONDXIVEhw'),
    getYoutubeVideo('Pull Up Walk Out', 'WraOQ4FVKWI'),
    getYoutubeVideo('Band Assisted Front Levers', 'zqyuqQTaj9I'),
    getYoutubeVideo('Quadriped From Plank', '0sj-hBP7Qu4'),
    getYoutubeVideo('Archer Pull Ups', 'kzUE3lQMIkM'),
    getYoutubeVideo('Straight Arm Pulls On A Swiss Ball', '7YK-bB8IRXc'),
    getYoutubeVideo('One Arm Mid Row', 'iOQQcK4lsV4'),
    getYoutubeVideo('Pumped Up Kick On A Swiss Ball', '45fuZxr7DHc'),
    getYoutubeVideo('Y Fly Back Extension', 'XOWZcIChH2Q'),
    getYoutubeVideo('Straight Arm Side Planks Repping', 'qsGo5h6_lyg'),
    getYoutubeVideo('Body Weight Curls On A Bar With Feet Elevated', 'MdEXvxgZ41M'),
    getYoutubeVideo('Mid Row Grip Strength With Feet Elevated', 'kbEGFmMMkvU'),
    getYoutubeVideo('Pistol Squats On A High Level', '-O7p0qJZeJ4'),
    getYoutubeVideo('Alternating Leg Extensions On A Box', 'MF-ITtnGRA8'),
    getYoutubeVideo('Turkish Get Ups', 'CmtjwA5v6vs'),
    getYoutubeVideo('Alternating Jumping Lunges', 'gqXVV6SjsEc'),
    getYoutubeVideo('Bar Modified Clean And Press', 'lsdkUN7LPI4'),
    getYoutubeVideo('Medicine Ball Lunge Press', 'dE9psQGcB8w'),
    getYoutubeVideo('One Legged Deadlift', 'RW5JjJOQn38'),
    getYoutubeVideo('Swiss Ball Glute Bridge With A Hamstring Curl', 'idbcKr3MHVg'),
    getYoutubeVideo('Farmers Walks', 'Vj_nMkzJjh8'),
    getYoutubeVideo('Ski Downs', 'YMKr4OoZwiM'),
  ],
}

export const Stage4: MisterioStageData = {
  title: 'Stage 4',
  docs: [workoutDocument(4)],
  videos: [
    getYoutubeVideo('Arm Circles', 'j1se74FaCXo'),
    getYoutubeVideo('Inchworm', 'UTpyOQQYIsw'),
    getYoutubeVideo('Prone Position Lumbar Rotaion', 'FoLUG5Jsbto'),
    getYoutubeVideo('Supine position Lumbar Rotation', 'oI8YlcWI2k4'),
    getYoutubeVideo('Push-Up Position IT Stretch', 'q0R098ZzNfY'),
    getYoutubeVideo('Bodyweight Squats', 'HfljNdqxVT0'),
    getYoutubeVideo('Wall Angles', 'DgGOpPcph9E'),
    getYoutubeVideo('Hanging Knee Raises', '5cjUzvrqmIY'),
    getYoutubeVideo('Hanging Scapular Shrugs', 'cpwgHnE7wjs'),
    getYoutubeVideo('Hanging Torso Twists', 'xzxNlysp-3c'),
    getYoutubeVideo('Dead Hang', '8Gb0BQbT6mY'),
    getYoutubeVideo('Thoracic Rotation With Weight', '5U3rOOyg1ak'),
    getYoutubeVideo('Push Up', 'PJjNd8rGzio'),
    getYoutubeVideo('Box Jumps', 'WOOstIq6RDA'),
    getYoutubeVideo('Low-Angle Tic Tacs', 'HgPNT14eeSw'),
    getYoutubeVideo('Advanced Get Ups', 'hbN1047T82Q'),
    getYoutubeVideo('Lower Body Extensions On A Bench', 'YCPn-0jZXDs'),
    getYoutubeVideo('Bar Modified Clean And Press', 'lsdkUN7LPI4'),
    getYoutubeVideo('Medicine Ball Lunge Press', 'dE9psQGcB8w'),
    getYoutubeVideo('Pistol Squat To A Low Level', 'OnOYUxmN2Kc'),
    getYoutubeVideo('TRX Swiss Ball Standing', '6VrJ9jDsnAE'),
    getYoutubeVideo('One Legged Deadlift', 'RW5JjJOQn38'),
    getYoutubeVideo('One Legged Swiss Ball Glute Bridge With A Hamstring Curl', 'thZI2-IbAzQ'),
    getYoutubeVideo('Farmers Walks', 'Vj_nMkzJjh8'),
    getYoutubeVideo('Front Lever Pulls', 'ylUEyzP7_qY'),
    getYoutubeVideo('Push-Up Position Quadriped', '8Yh5WwKQxNM'),
    getYoutubeVideo('Band Assisted 1 Arm Pull Up', 'uoONDXIVEhw'),
    getYoutubeVideo('Straight Arm Side Planks Repping', 'qsGo5h6_lyg'),
    getYoutubeVideo('Explosive Pull Ups', '45FUhUuYl4E'),
    getYoutubeVideo('Straight Arm Pulls With A Jack Knife', 'nqG7He-ujHA'),
    getYoutubeVideo('Ball Assisted Quadriped Mid Row', '_Zvv7aqUtGw'),
    getYoutubeVideo('Bubkas', 'gD54GHcwUh0'),
    getYoutubeVideo('Weighted Chin Up', 'EZ_HLhBm8TQ'),
    getYoutubeVideo('Y Fly Back Extension', 'XOWZcIChH2Q'),
    getYoutubeVideo('Bodyweight Rear Delt Fly', 'dWqoupNUsgQ'),
    getYoutubeVideo('Bodyweight TRX Curl', '_M9msGTPAfs'),
    getYoutubeVideo('Pull Up Matrix', '3ZKTNYdwL-s'),
    getYoutubeVideo('Wall Assisted Crow Stance Handstand', 'o_jDIm4Yr-E'),
    getYoutubeVideo('Ball Assisted Quadriped Push Up', 'Z-v0rtUApgk'),
    getYoutubeVideo('Pull Up Bar Grip Strength', 'BpWYDsBORYE'),
    getYoutubeVideo('Full Body Rises', 'wh1TnIFpgo8'),
    getYoutubeVideo('Two Armed Freestylers', 'hXFFoZF4edM'),
    getYoutubeVideo('Side Plank Push Ups', 'nzxJcwwCQ0Y'),
    getYoutubeVideo('Hollow Body Hold', 'JlJ7U-2WE64'),
    getYoutubeVideo('Push Up To Dip', 'nBy-scQo9oI'),
    getYoutubeVideo('Scorpion Position Lumbar Rotation On A Swiss Ball', 'ligpKXwNKBk'),
    getYoutubeVideo('Around The World', 'Kl6eMN6zmok')
  ],
}

export const Stage5: MisterioStageData = {
  title: 'Stage 5',
  docs: [],
  videos: [],
}
