import { AccountInfo, InteractionStatus } from '@azure/msal-browser'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { ActionIcon, Badge, Button, createStyles, Flex, Loader, Popover } from '@mantine/core'
import { useClickOutside, useDisclosure } from '@mantine/hooks'
import { IconUser } from '@tabler/icons'
import { Link } from 'react-router-dom'
import { b2cPolicies, loginRequest } from '../auth-config'
import { hasUserProduct } from '../helpers/user.helper'
import { MISTERIO_PRODUCT_ID } from '../products/misterio-project-product'

const useStyles = createStyles((theme) => ({
  popoverContentWrapper: {
    width: '100%',
  },

  misterioBadge: {
    fontSize: theme.fontSizes.xs,
  },
}))

export const ToolbarAuth = () => {
  const hasMisterio = hasUserProduct(MISTERIO_PRODUCT_ID)

  const { classes } = useStyles()
  const [opened, { toggle }] = useDisclosure(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ref = useClickOutside(() => {
    if (opened) toggle()
  })

  const { instance, inProgress } = useMsal()
  let activeAccount: AccountInfo | null = null

  if (instance) {
    activeAccount = instance.getActiveAccount()
  }

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error))
  }

  const handleLogoutRedirect = () => {
    instance.logoutRedirect()
  }

  const handleProfileEdit = () => {
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenRedirect({ scopes: [], ...b2cPolicies.authorities.editProfile })
    }
  }

  const username = activeAccount?.name ?? 'UNKNOWN'

  const PopoverContent = () => {
    if (inProgress !== InteractionStatus.None) {
      return <Loader />
    }

    return (
      <Flex
        className={classes.popoverContentWrapper}
        direction={'column'}
        justify={'center'}
        align={'start'}
        gap={8}
      >
        <AuthenticatedTemplate>
          <Flex w={'100%'} justify='center' align='center' gap={'sm'}>
            {username}
            {hasMisterio && (
              <Badge
                className={classes.misterioBadge}
                variant='gradient'
                gradient={{ from: 'red', to: '#232323' }}
              >
                Misterio
              </Badge>
            )}
          </Flex>

          <Link to={'/orders'} style={{ width: '100%' }}>
            <Button w={'100%'}>
              My Orders
            </Button>
          </Link>

          <Button onClick={handleProfileEdit} w={'100%'}>
            Edit Profile
          </Button>

          <Button onClick={handleLogoutRedirect} w={'100%'}>
            Logout
          </Button>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Button onClick={handleLoginRedirect} w={'100%'}>
            Login
          </Button>
        </UnauthenticatedTemplate>
      </Flex>
    )
  }

  return (
    <Flex direction={'column'}>
      <Popover position='bottom' withArrow shadow='md'>
        <Popover.Target>
          <ActionIcon size='lg' component='a' onClick={toggle} variant='light'>
            <IconUser size={18} stroke={1.5} />
          </ActionIcon>
        </Popover.Target>

        <Popover.Dropdown>
          <PopoverContent />
        </Popover.Dropdown>
      </Popover>
    </Flex>
  )
}
